import { defineMessages } from 'react-intl'

export interface Message {
  id: string
  defaultMessage: string
}

export const messages = defineMessages({
  REQUISITION_NUMBER: {
    id: 'REQUISITION_NUMBER',
    defaultMessage: 'Requisition Number',
  },
  RECIPIENT_CONTACT: {
    id: 'RECIPIENT_CONTACT',
    defaultMessage: 'Recipient Contact',
  },
  ORDER_BILLING_ADDRESS: {
    id: 'ORDER_BILLING_ADDRESS',
    defaultMessage: 'Billing Address',
  },
  PACKING_SLIP_NOTE: {
    id: 'PACKING_SLIP_NOTE',
    defaultMessage: 'Packing Slip Note',
  },
  CUSTOMER_SERVICE_INSTRUCTIONS: {
    id: 'CUSTOMER_SERVICE_INSTRUCTIONS',
    defaultMessage: 'Customer Service Instructions',
  },
  MIN_3_CHARACTERS: {
    id: 'MIN_3_CHARACTERS',
    defaultMessage: 'Must be 3 characters minimum',
  },
  MAX_5000_CHARACTERS: {
    id: 'MAX_5000_CHARACTERS',
    defaultMessage: '5,000 characters max',
  },
  MAX_35_CHARACTERS: {
    id: 'MAX_35_CHARACTERS',
    defaultMessage: '35 characters max',
  },
  REFERENCE_NUMBER: {
    id: 'REFERENCE_NUMBER',
    defaultMessage: 'Reference Number',
  },
  CUSTOMER_PO_NUMBER: {
    id: 'CUSTOMER_PO_NUMBER',
    defaultMessage: 'Customer PO Number',
  },
  CREDIT_CARD: {
    id: 'CC_ENDING_IN',
    defaultMessage:
      '{type, select,\n      VI {Visa}\n      MC {MasterCard}\n      AX {American Express}\n      other {Credit Card}\n    } ending in {number}',
  },
  MPOWER_NUMBER: {
    id: 'MPOWER_NUMBER',
    defaultMessage: 'MPower Payment Account Number',
  },
  RAPIDRED_NUMBER: {
    id: 'RAPIDRED_NUMBER',
    defaultMessage: 'RapidRed™ Payment Account Number',
  },
  BLANKETPO_NUMBER: {
    id: 'BLANKETPO_NUMBER',
    defaultMessage: 'Blanket PO Number',
  },
  CONTRACT_NUMBER: { id: 'CONTRACT_NUMBER', defaultMessage: 'Contract Number' },
  DEALER_DIALOG_TITLE: {
    id: 'DEALER_DIALOG_TITLE',
    defaultMessage: 'Dealer Country',
  },
  DEALER_DIALOG_BODY: {
    id: 'DEALER_DIALOG_BODY',
    defaultMessage: 'Are you an existing Direct Sales Customer or Dealer?',
  },
  NO: { id: 'NO', defaultMessage: 'No' },
  YES: { id: 'YES', defaultMessage: 'Yes' },
  ORDER_ACKNOWLEDGEMENT_MARKETPLACE_BANNER_CONTENT_1: {
    id: 'ORDER_ACKNOWLEDGEMENT_MARKETPLACE_BANNER_CONTENT_1',
    defaultMessage: 'Don’t forget! You still have',
  },
  ORDER_ACKNOWLEDGEMENT_MARKETPLACE_BANNER_CONTENT_2: {
    id: 'ORDER_ACKNOWLEDGEMENT_MARKETPLACE_BANNER_CONTENT_2',
    defaultMessage: '{qty} Marketplace products',
  },
  ORDER_ACKNOWLEDGEMENT_BANNER_CONTENT_2: {
    id: 'ORDER_ACKNOWLEDGEMENT_BANNER_CONTENT_2',
    defaultMessage: '{qty} {companyName} products',
  },
  ORDER_ACKNOWLEDGEMENT_MARKETPLACE_BANNER_CONTENT_3: {
    id: 'ORDER_ACKNOWLEDGEMENT_MARKETPLACE_BANNER_CONTENT_3',
    defaultMessage: 'in your shopping cart.',
  },
  MILLIPORE_SIGMA_CART: {
    id: 'MILLIPORE_SIGMA_CART',
    defaultMessage: 'MilliporeSigmaCart',
  },
  MERCK_CART: {
    id: 'MERCK_CART',
    defaultMessage: 'MerckCart',
  },
  VIEW_CART: { id: 'VIEW_CART', defaultMessage: 'View Cart' },
  TECHNICAL_DIFFICULTIES_ORDER_ACKNOWLEDGEMENT: {
    id: 'TECHNICAL_DIFFICULTIES_ORDER_ACKNOWLEDGEMENT',
    defaultMessage:
      'We are currently experiencing some technical difficulties. Your order did submit but we were unable to return the confirmation number at this time. Please contact Customer Support for your confirmation number.',
  },
  ORDER_DETAILS: { id: 'ORDER_DETAILS', defaultMessage: 'Order Details' },
  ORDER_ACKNOWLEDGEMENT: {
    id: 'ORDER_ACKNOWLEDGEMENT',
    defaultMessage: 'Order Acknowledgement',
  },
  RETURN_TO_LIST: { id: 'RETURN_TO_LIST', defaultMessage: 'Return to List' },
  CONFIRM_EMAIL_CONFIRMATION: {
    id: 'CONFIRM_EMAIL_CONFIRMATION',
    defaultMessage:
      'You will receive an email confirmation once your order has been confirmed.',
  },
  MARKETPLACE_QUESTIONS: {
    id: 'MARKETPLACE_QUESTIONS',
    defaultMessage:
      'If you have questions or to view the status of your Marketplace order, visit the',
  },
  ORDER_HISTORY: { id: 'ORDER_HISTORY', defaultMessage: 'Order History' },
  BACK_TO_ORDER_CENTER: {
    id: 'BACK_TO_ORDER_CENTER',
    defaultMessage: 'Back to Order Center',
  },
  SKU: { id: 'SKU', defaultMessage: 'SKU' },
  DOCUMENT_NAME: { id: 'DOCUMENT_NAME', defaultMessage: 'Document Name' },
  DOWNLOAD: { id: 'DOWNLOAD', defaultMessage: 'Download' },
  REQUIRED_COMPLIANCE_DOCUMENTS: {
    id: 'REQUIRED_COMPLIANCE_DOCUMENTS',
    defaultMessage: 'Required Compliance Documents',
  },
  REQUIRED_COMPLIANCE_DOCUMENTS_MESSAGE: {
    id: 'REQUIRED_COMPLIANCE_DOCUMENTS_MESSAGE',
    defaultMessage:
      'The following items require duly filled compliance documentation on end user letter head before the order can be shipped. Please download, complete the documentation and email them along with this order number to {mailTo}.',
  },
  REQUIRED_COMPLIANCE_DOCUMENTS_CLOSE_MESSAGE: {
    id: 'REQUIRED_COMPLIANCE_DOCUMENTS_CLOSE_MESSAGE',
    defaultMessage:
      'I acknowledge this order will not ship until these documents have been emailed to {mailTo}.',
  },
  CLOSE: { id: 'CLOSE', defaultMessage: 'Close' },
  ORDER_SUMMARY: { id: 'ORDER_SUMMARY', defaultMessage: 'Order Summary' },
  ADDITIONAL_INFO_POPOVER: {
    id: 'ADDITIONAL_INFO_POPOVER',
    defaultMessage: 'View Additional Information',
  },
  PRODUCT_AVAILABILITY_NOT_CALCULATED: {
    id: 'PRODUCT_AVAILABILITY_NOT_CALCULATED',
    defaultMessage:
      'Product Availability: You will receive an order acknowledgement once the order has been entered in our system.',
  },
  SHIPPING_DATE_TO_BE_DETERMINED: {
    id: 'SHIPPING_DATE_TO_BE_DETERMINED',
    defaultMessage: 'Shipping date to be determined',
  },
  QUOTE_ITEM: { id: 'QUOTE_ITEM', defaultMessage: 'Quote Item' },
  QUOTE_NAME: { id: 'QUOTE_NAME', defaultMessage: 'Quote Name' },
  NAME: { id: 'NAME', defaultMessage: 'Name' },
  REQUESTED_SHIPPING_DATE: {
    id: 'REQUESTED_SHIPPING_DATE',
    defaultMessage: 'Requested Shipping Date',
  },
  REQUESTED_DELIVERY_DATE: {
    id: 'REQUESTED_DELIVERY_DATE',
    defaultMessage: 'Requested Delivery Date',
  },
  LOT_NUMBER: { id: 'LOT_NUMBER', defaultMessage: 'Lot Number' },
  SPECIFICATION_SHEET: {
    id: 'SPECIFICATION_SHEET',
    defaultMessage: 'Specification Sheet',
  },
  ESTIMATED_TO_SHIP: {
    id: 'ESTIMATED_TO_SHIP',
    defaultMessage: 'Estimated to Ship',
  },
  SHIPPING_METHOD: { id: 'SHIPPING_METHOD', defaultMessage: 'Shipping Method' },
  QUOTE_NUMBER: { id: 'QUOTE_NUMBER', defaultMessage: 'Quote Number' },
  LOT_BATCH: { id: 'LOT_BATCH', defaultMessage: 'Lot/Batch' },
  QUANTITY_REMAINING: {
    id: 'QUANTITY_REMAINING',
    defaultMessage: 'Quantity Remaining',
  },
  OF: { id: 'OF', defaultMessage: 'of' },
  ROUTE_INFO: { id: 'ROUTE_INFO', defaultMessage: 'Routing' },
  SINGLE_LOT: { id: 'SINGLE_LOT', defaultMessage: 'Single Lot' },
  VENDOR_NUMBER: { id: 'VENDOR_NUMBER', defaultMessage: 'Vendor Number' },
  PROMO_CODE: { id: 'PROMO_CODE', defaultMessage: 'Promo Code' },
  PRODUCT_NOTE: { id: 'PRODUCT_NOTE', defaultMessage: 'Packing Slip Note' },
  VIEW_YOUR_LICENSES: {
    id: 'VIEW_YOUR_LICENSES',
    defaultMessage: 'View your licenses',
  },
  ORDER_CONFIRMATION_HEADER_NOTE_IN_REVIEW: {
    id: 'ORDER_CONFIRMATION_HEADER_NOTE_IN_REVIEW',
    defaultMessage: 'Thank you! You Submitted Request',
  },
  ORDER_DISCLAIMER_TEXT: {
    id: 'ORDER_DISCLAIMER_TEXT',
    defaultMessage:
      'Thank you for placing your Request with {organizationName} through the {companyName} website.\nWe will review your request before forwarding to {organizationName}. When approved, {organizationName} representatives will provide an order confirmation or contact you directly should they need any further information.\nFor questions on order status, please contact {organizationName} or',
  },
  EMPROVE_ORDER_CONFIRMATION_HEADER_NOTE_PENDING: {
    id: 'EMPROVE_ORDER_CONFIRMATION_HEADER_NOTE_PENDING',
    defaultMessage: 'Thank you. You Submitted',
  },
  EMPROVE_ORDER_ACK_PENDING_HEADER_TEXT: {
    id: 'EMPROVE_ORDER_ACK_PENDING_HEADER_TEXT',
    defaultMessage:
      'Thank you for submitting your order with {legalCompanyName}.',
  },
  EMPROVE_ORDER_ACK_PENDING_STATUS_TEXT: {
    id: 'EMPROVE_ORDER_ACK_PENDING_STATUS_TEXT',
    defaultMessage:
      'Important Note - Emprove® Dossiers contain confidential information for pharmaceutical producers. Access to your purchase will be granted only after we determine your status as a pharmaceutical producer. We will contact you by email with the next steps. If you have any questions, please contact',
  },
  EMPROVE_ORDER_CONFIRMATION_HEADER_NOTE_APPROVED: {
    id: 'EMPROVE_ORDER_CONFIRMATION_HEADER_NOTE_APPROVED',
    defaultMessage: 'You Submitted Order',
  },
  EMPROVE_ORDER_CONFIRMATION_HEADER_STATUS_APPROVED: {
    id: 'EMPROVE_ORDER_CONFIRMATION_HEADER_STATUS_APPROVED',
    defaultMessage: 'You have successfully completed your order.',
  },
  ORDER_CONFIRMATION_TEXT_IN_REVIEW_INDIA: {
    id: 'ORDER_CONFIRMATION_TEXT_IN_REVIEW_INDIA',
    defaultMessage:
      'Due to nature of products we sell, we are required to review your profile before processing your order. This review process in some situations may take several business days. We will be reaching out to you for more information. Questions? Please call Customer Service',
  },
  ORDER_CONFIRMATION_TEXT_IN_REVIEW: {
    id: 'ORDER_CONFIRMATION_TEXT_IN_REVIEW',
    defaultMessage:
      "We will need to review your information before processing the order. When you've been approved to order online, you will receive an email confirmation with your order number. Questions? Please call Customer Service",
  },
  ORDER_CONFIRMATION_VIEW_ORDER_IN_REVIEW: {
    id: 'ORDER_CONFIRMATION_VIEW_ORDER_IN_REVIEW',
    defaultMessage: 'View Pending Order',
  },
  ORDER_CONFIRMATION_APPROVER: {
    id: 'ORDER_CONFIRMATION_APPROVER',
    defaultMessage: 'You approved Order',
  },
  PENDING_ORDER: { id: 'PENDING_ORDER', defaultMessage: 'Pending Order' },
  ORDER_CONFIRMATION_TEXT: {
    id: 'ORDER_CONFIRMATION_TEXT',
    defaultMessage:
      'Information related to price, availability and delivery dates is valid at the time of an order creation but does not consider delays due to manual processing due to address changes or order comments added in the cart or additional review due to credit, compliance or dangerous goods restrictions.',
  },
  ORDER_CONFIRMATION_APPROVEE: {
    id: 'ORDER_CONFIRMATION_APPROVEE',
    defaultMessage: 'Your order has been submitted for approval',
  },
  ORDER_CONFIRMATION_CONSOLIDATEE: {
    id: 'ORDER_CONFIRMATION_CONSOLIDATEE',
    defaultMessage: 'Thank you.',
  },
  ORDER_CONFIRMATION_LINE_1A_CONSOLIDATEE: {
    id: 'ORDER_CONFIRMATION_LINE_1A_CONSOLIDATEE',
    defaultMessage: 'Your order has been forwarded to',
  },
  ORDER_CONFIRMATION_LINE_1B_CONSOLIDATEE: {
    id: 'ORDER_CONFIRMATION_LINE_1B_CONSOLIDATEE',
    defaultMessage: 'for Consolidation.',
  },
  ORDER_CONFIRMATION_LINE_2_CONSOLIDATEE: {
    id: 'ORDER_CONFIRMATION_LINE_2_CONSOLIDATEE',
    defaultMessage: 'The reference ID for the order is',
  },
  ORDER_CONFIRMATION_LINE_3_CONSOLIDATEE: {
    id: 'ORDER_CONFIRMATION_LINE_3_CONSOLIDATEE',
    defaultMessage:
      'Reference this ID in any communication with your consolidator.',
  },
  ORDER_CONFIRMATION_LINE_4_CONSOLIDATEE: {
    id: 'ORDER_CONFIRMATION_LINE_4_CONSOLIDATEE',
    defaultMessage:
      'Once the order is submitted to {legalCompanyName}, a new confirmation number will be assigned.',
  },
  BLANKET_PO_NUMBER: {
    id: 'BLANKET_PO_NUMBER',
    defaultMessage: 'Blanket PO Number',
  },
  EMD_ORDER_CONFIRMATION_HEADER_NOTE: {
    id: 'EMD_ORDER_CONFIRMATION_HEADER_NOTE',
    defaultMessage: 'Thank you for your order!',
  },
  MERCK_CHEMICALS_ORDER_CONFIRMATION_HEADER_NOTE: {
    id: 'MERCK_CHEMICALS_ORDER_CONFIRMATION_HEADER_NOTE',
    defaultMessage: 'Thank you for your order!',
  },
  ORDER_CONFIRMATION_HEADER_NOTE: {
    id: 'ORDER_CONFIRMATION_HEADER_NOTE',
    defaultMessage: 'Thank you! Your order number is',
  },
  COMPLIANCE_DOCUMENTATION_REQUIRED: {
    id: 'COMPLIANCE_DOCUMENTATION_REQUIRED',
    defaultMessage:
      'Completed compliance documentation is required before this order will ship. ',
  },
  VIEW_DOWNLOADABLE_DOCUMENTS: {
    id: 'VIEW_DOWNLOADABLE_DOCUMENTS',
    defaultMessage: 'Click to view a list of downloadable documents.',
  },
  ORDER_CONFIRMATION_VIEW_ORDER: {
    id: 'ORDER_CONFIRMATION_VIEW_ORDER',
    defaultMessage: 'View order',
  },
  EMD_ORDER_NUMBER_NOTE: {
    id: 'EMD_ORDER_NUMBER_NOTE',
    defaultMessage:
      'This is a temporary order number. You will receive an email with the updated order number once the order has processed.',
  },
  MERCK_CHEMICALS_ORDER_NUMBER_NOTE: {
    id: 'MERCK_CHEMICALS_ORDER_NUMBER_NOTE',
    defaultMessage:
      'This is a temporary order number. You will receive an email with the updated order number once the order has processed.',
  },
  ORDER_CENTER_NOTE: {
    id: 'ORDER_CENTER_NOTE',
    defaultMessage:
      'To troubleshoot or view the status of your orders, please visit the',
  },
  ORDER_CONFIRMATION_ALERT_TEXT1: {
    id: 'ORDER_CONFIRMATION_ALERT_TEXT1',
    defaultMessage:
      'You may also check order status, tracking details, Fapiaos, and more through our',
  },
  ORDER_CONFIRMATION_ALERT_TEXT2: {
    id: 'ORDER_CONFIRMATION_ALERT_TEXT2',
    defaultMessage: 'Wechat Subscription Account.',
  },
  PRINT: { id: 'PRINT', defaultMessage: 'Print' },
  ACCOUNT_ID: { id: 'ACCOUNT_ID', defaultMessage: 'Accounts' },
  ORGANIZATION: { id: 'ORGANIZATION', defaultMessage: 'Organization' },
  STREET: { id: 'STREET', defaultMessage: 'Street' },
  ATTENTION_TO: { id: 'ATTENTION_TO', defaultMessage: 'Attention To' },
  DEPT: { id: 'DEPT', defaultMessage: 'Dept' },
  CART_BUILDING_RM: { id: 'CART_BUILDING_RM', defaultMessage: 'Building/Room' },
  CITY: { id: 'CITY', defaultMessage: 'City' },
  REGION_PROVINCE: { id: 'REGION_PROVINCE', defaultMessage: 'Region' },
  POSTAL: { id: 'POSTAL', defaultMessage: 'Postal' },
  USE_SELECTED_ADDRESS: {
    id: 'USE_SELECTED_ADDRESS',
    defaultMessage: 'Use Selected Address',
  },
  SELECT_DIFFERENT_ADDRESS: {
    id: 'SELECT_DIFFERENT_ADDRESS',
    defaultMessage: 'Select Different Address',
  },
  SHIPPING: { id: 'SHIPPING', defaultMessage: 'Shipping' },
  BILLING: { id: 'BILLING', defaultMessage: 'Billing' },
  PAYER: { id: 'PAYER', defaultMessage: 'Payer' },
  ADDRESS: { id: 'ADDRESS', defaultMessage: 'Address' },
  SELECT_RECIPIENT: {
    id: 'SELECT_RECIPIENT',
    defaultMessage: 'Select Recipient',
  },
  NONE: { id: 'NONE', defaultMessage: 'None' },
  SELECT_ADDRESSES: {
    id: 'SELECT_ADDRESSES',
    defaultMessage: 'Select Addresses',
  },
  PROCEED_TO_QUOTE: {
    id: 'PROCEED_TO_QUOTE',
    defaultMessage: 'Proceed to Quote',
  },
  PROCEED_TO_CHECKOUT: {
    id: 'PROCEED_TO_CHECKOUT',
    defaultMessage: 'Proceed to Checkout',
  },
  CART_ALCOHOL_TAX: { id: 'CART_ALCOHOL_TAX', defaultMessage: 'Alcohol Tax' },
  CART_CARRIER_HAZARD_FEE: {
    id: 'CART_CARRIER_HAZARD_FEE',
    defaultMessage: 'Carrier Hazard Fee',
  },
  CART_COMPLIANCE_FEE: {
    id: 'CART_COMPLIANCE_FEE',
    defaultMessage: 'DEA Administration Charge',
  },
  CART_DUTY_TOTAL: { id: 'CART_DUTY_TOTAL', defaultMessage: 'Duty' },
  CART_ELECTRICAL_FEE: {
    id: 'CART_ELECTRICAL_FEE',
    defaultMessage: 'Electrical Inspection Fee',
  },
  CART_MASS_CHARGE: {
    id: 'CART_MASS_CHARGE',
    defaultMessage: 'European Volatile Mass Charge',
  },
  CART_ICE_CHARGE: {
    id: 'CART_ICE_CHARGE',
    defaultMessage: 'Ice/Special Packaging Charges',
  },
  CART_TRANSPORTATION_CHARGE: {
    id: 'CART_TRANSPORTATION_CHARGE',
    defaultMessage: 'Transportation/Handling',
  },
  PRICE_NOT_CALCULATED_SHORT: {
    id: 'PRICE_NOT_CALCULATED_SHORT',
    defaultMessage: 'Price Not Calculated',
  },
  REVIEW_CART: { id: 'REVIEW_CART', defaultMessage: 'Review Cart' },
  YOUR_INFORMATION: {
    id: 'YOUR_INFORMATION',
    defaultMessage: 'Your Information',
  },
  SUBMIT_REQUEST: { id: 'SUBMIT_REQUEST', defaultMessage: 'Submit Request' },
  SUBMITTED_FOR_REVIEW: {
    id: 'SUBMITTED_FOR_REVIEW',
    defaultMessage: 'Submitted for Review',
  },
  CART: { id: 'CART', defaultMessage: 'Cart' },
  MARKETPLACE_CHECKOUT: {
    id: 'MARKETPLACE_CHECKOUT',
    defaultMessage: 'Marketplace Checkout',
  },
  CHECKOUT: { id: 'CHECKOUT', defaultMessage: 'Checkout' },
  CART_CHECKOUT: { id: 'CART_CHECKOUT', defaultMessage: 'Checkout' },
  MERCK_CHECKOUT: { id: 'MERCK_CHECKOUT', defaultMessage: 'Merck Checkout' },
  REQUEST_QUOTE: { id: 'REQUEST_QUOTE', defaultMessage: 'Request Quote' },
  SELECT_ACCOUNT: { id: 'SELECT_ACCOUNT', defaultMessage: 'Select Account' },
  CUSTOMER_SEARCH: { id: 'CUSTOMER_SEARCH', defaultMessage: 'Customer Search' },
  REFILL_CARD: { id: 'REFILL_CARD', defaultMessage: 'Refill Account' },
  QUOTE_CONFIRMATION_HEADER_NOTE: {
    id: 'QUOTE_CONFIRMATION_HEADER_NOTE',
    defaultMessage: 'You completed Quote',
  },
  VIEW_QUOTE: { id: 'VIEW_QUOTE', defaultMessage: 'View Quote' },
  PRINT_ORDER: { id: 'PRINT_ORDER', defaultMessage: 'Print Order' },
  QUOTE_BILLING_ADDRESS: {
    id: 'QUOTE_BILLING_ADDRESS',
    defaultMessage: 'Billing Address',
  },
  QUOTE_CONFIRMATION: {
    id: 'QUOTE_CONFIRMATION',
    defaultMessage: 'Quote Confirmation',
  },
  PO_NON_EDITABLE_ERROR: {
    id: 'PO_NON_EDITABE',
    defaultMessage:
      'One or more of the payment fields in the cart is required but set to non-editable. Please contact customer service for assistance.',
  },
  MONTH_CANNOT_BE_IN_THE_PAST: {
    id: 'MONTH_CANNOT_BE_IN_THE_PAST',
    defaultMessage: 'Month cannot be in the past',
  },
  YEAR_CANNOT_BE_IN_PAST: {
    id: 'YEAR_CANNOT_BE_IN_PAST',
    defaultMessage: 'Year cannot be in the past',
  },
  PIN_CODE_INVALID_CART: {
    id: 'PIN_CODE_INVALID_CART',
    defaultMessage: 'invalid for the selected location',
  },
  TECHNICAL_DIFFICULTIES: {
    id: 'TECHNICAL_DIFFICULTIES',
    defaultMessage:
      'We are currently experiencing some technical difficulties. Please try again later.',
  },
  CREDITCARD_NEEDED: {
    id: 'CREDITCARD_NEEDED',
    defaultMessage: 'Credit card needed to submit your orders',
  },
  SHIPPING_ATTENTION_TO_EMPTY: {
    id: 'SHIPPING_ATTENTION_TO_EMPTY',
    defaultMessage: 'Attention To Field Required',
  },
  SHIPPING_CITY_INVALID: {
    id: 'SHIPPING_CITY_INVALID',
    defaultMessage: 'invalid: Must be 30 characters max',
  },
  BILLING_CITY_INVALID: {
    id: 'BILLING_CITY_INVALID',
    defaultMessage: 'invalid: Must be 30 characters max',
  },
  CART_CC_ERROR_MSG: {
    id: 'CART_CC_ERROR_MSG',
    defaultMessage: 'Please separate email addresses with commas.',
  },
  PHONE_FAX_NO_HELP_MSG: {
    id: 'PHONE_FAX_NO_HELP_MSG',
    defaultMessage:
      'Invalid character entered. Allowed Characters are: Numbers - + ( )',
  },
  EMAIL_MUST_BE_VALID: {
    id: 'EMAIL_MUST_BE_VALID',
    defaultMessage: 'Must be valid email address',
  },
  USERNAME_REQUIRED: {
    id: 'USERNAME_REQUIRED',
    defaultMessage: 'Username required',
  },
  NUMERIC_PHONE_WITH_SPACE: {
    id: 'NUMERIC_PHONE_WITH_SPACE',
    defaultMessage:
      'Invalid character entered. Allowed Characters are: Numbers Space - ( )',
  },
  GENERIC_ERROR: {
    id: 'GENERIC_ERROR',
    defaultMessage: 'Sorry, an unexpected error has occurred',
  },
  TECHNICAL_DIFFICULTIES_RETRY_WITH_CONTACT_NUMBER: {
    id: 'TECHNICAL_DIFFICULTIES_RETRY_WITH_CONTACT_NUMBER',
    defaultMessage:
      'We are currently experiencing some technical difficulties. Please try submitting the order again later. If you have any questions, please contact Customer Support at {phoneNumber}',
  },
  TECHNICAL_DIFFICULTIES_RETRY: {
    id: 'TECHNICAL_DIFFICULTIES_RETRY',
    defaultMessage:
      'We are currently experiencing some technical difficulties. Please try submitting the order again later. If you have any questions, please contact Customer Support',
  },
  CART_ALREADY_SUBMITTED_FOR_ORDERING: {
    id: 'CART_ALREADY_SUBMITTED_FOR_ORDERING',
    defaultMessage:
      'This Cart is currently being processed. Please visit the {link} to find your Transaction.',
  },
  ERROR_WHILE_SUBMITTING_CARD_INFO: {
    id: 'ERROR_WHILE_SUBMITTING_CARD_INFO',
    defaultMessage:
      'An error occurred when submitting the cart. Please {link} and try again.',
  },
  CLICK_HERE: { id: 'CLICK_HERE', defaultMessage: 'Click here' },
  DUPLICATE_ORDER_DIALOG_TITLE: {
    id: 'DUPLICATE_ORDER_DIALOG_TITLE',
    defaultMessage: 'Warning: Duplicate Order',
  },
  DUPLICATE_ORDER_DIALOG_BODY: {
    id: 'DUPLICATE_ORDER_DIALOG_BODY',
    defaultMessage:
      'This order appears to be a duplicate of Order Number: {orderNo}, which was placed within the last hour.',
  },
  CANCEL_QUOTE: { id: 'CANCEL_QUOTE', defaultMessage: 'Cancel Quote' },
  CANCEL_ORDER: { id: 'CANCEL_ORDER', defaultMessage: 'Cancel Order' },
  SUBMIT_QUOTE: { id: 'SUBMIT_QUOTE', defaultMessage: 'Submit Quote' },
  SUBMIT_ORDER: { id: 'SUBMIT_ORDER', defaultMessage: 'Submit Order' },
  BLANKETPO_EMPTY: {
    id: 'BLANKETPO_EMPTY',
    defaultMessage: 'Blanket PO required',
  },
  BLANKETPO_PIN_EMPTY: {
    id: 'BLANKETPO_PIN_EMPTY',
    defaultMessage: 'Blanket PO PIN required',
  },
  //Message returned as endpoint error. Do not modify or delete key.
  INVALID_PIN_NUMBER: {
    id: 'INVALID_PIN_NUMBER',
    defaultMessage: 'PIN number is invalid',
  },
  BLANKETPO_CONTRACT_NUMBER_IS_INVALID: {
    id: 'BLANKETPO_CONTRACT_NUMBER_IS_INVALID',
    defaultMessage: 'The Blanket PO number is invalid',
  },
  BLANKETPO_INVALID_PIN_NUMBER: {
    id: 'BLANKETPO_INVALID_PIN_NUMBER',
    defaultMessage: 'The Blanket PO Payment Account PIN number is invalid',
  },
  BLANKETPO_QUOTE: {
    id: 'BLANKETPO_QUOTE',
    defaultMessage:
      'The Blanket PO payment method cannot be applied to a Quote.',
  },
  BLANKETPO_MAPPING: {
    id: 'BLANKETPO_MAPPING',
    defaultMessage:
      'The Organization in this cart does not match the Organization on the Blanket PO. Please select the proper Organization and try again.',
  },
  BLANKETPO_CONTRACT_TARGET_PRICE_EXCEEDED: {
    id: 'BLANKETPO_CONTRACT_TARGET_PRICE_EXCEEDED',
    defaultMessage:
      'The order exceeds the amount remaining on this Blanket PO.',
  },
  BLANKETPO_CONTRACT_EXPIRED: {
    id: 'BLANKETPO_CONTRACT_EXPIRED',
    defaultMessage: 'The Blanket PO is expired.',
  },
  MPOWER_CONTRACT_NUMBER_IS_EMPTY: {
    id: 'MPOWER_CONTRACT_NUMBER_IS_EMPTY',
    defaultMessage: 'MPower Payment Account number required',
  },
  RAPIDRED_CONTRACT_NUMBER_IS_EMPTY: {
    id: 'RAPIDRED_CONTRACT_NUMBER_IS_EMPTY',
    defaultMessage: 'RapidRed™ Card number required',
  },
  MPOWER_CONTRACT_PIN_IS_EMPTY: {
    id: 'MPOWER_CONTRACT_PIN_IS_EMPTY',
    defaultMessage: 'MPower PIN required',
  },
  RAPIDRED_CONTRACT_PIN_IS_EMPTY: {
    id: 'RAPIDRED_CONTRACT_PIN_IS_EMPTY',
    defaultMessage: 'RapidRed™ PIN required',
  },
  MPOWER_NUMBER_INVALID: {
    id: 'MPOWER_NUMBER_INVALID',
    defaultMessage: 'The MPower Payment Account number is invalid.',
  },
  RAPIDRED_NUMBER_INVALID: {
    id: 'RAPIDRED_NUMBER_INVALID',
    defaultMessage: 'The RapidRed™ Payment Account number is invalid.',
  },
  MPOWER_INVALID_PIN_NUMBER: {
    id: 'MPOWER_INVALID_PIN_NUMBER',
    defaultMessage: 'The MPower Payment Account PIN number is invalid',
  },
  RAPIDRED_INVALID_PIN_NUMBER: {
    id: 'RAPIDRED_INVALID_PIN_NUMBER',
    defaultMessage: 'The RapidRed™ Payment Account PIN number is invalid',
  },
  CANNOT_SUBMIT_QUOTE_WITH_MPOWER: {
    id: 'CANNOT_SUBMIT_QUOTE_WITH_MPOWER',
    defaultMessage:
      'The MPower Payment Account payment method cannot be applied to a Quote.',
  },
  CANNOT_SUBMIT_QUOTE_WITH_RAPID_RED: {
    id: 'CANNOT_SUBMIT_QUOTE_WITH_RAPID_RED',
    defaultMessage:
      'The RapidRed™ Card payment method cannot be applied to a Quote.',
  },
  MPOWER_MAPPING: {
    id: 'MPOWER_MAPPING',
    defaultMessage:
      'The Organization in this cart does not match the Organization on the MPower. Please select the proper Organization and try again.',
  },
  RAPIDRED_MAPPING: {
    id: 'RAPIDRED_MAPPING',
    defaultMessage:
      'The Organization in this cart does not match the Organization on the RapidRed™. Please select the proper Organization and try again.',
  },
  MPOWER_CONTRACT_TARGET_PRICE_EXCEEDED: {
    id: 'MPOWER_CONTRACT_TARGET_PRICE_EXCEEDED',
    defaultMessage:
      'The order exceeds the amount remaining on this MPower Payment Account. {link}',
  },
  MPOWER_REFILL_NOW: {
    id: 'MPOWER_REFILL_NOW',
    defaultMessage: 'Refill Now',
  },
  RAPIDRED_CONTRACT_TARGET_PRICE_EXCEEDED: {
    id: 'RAPIDRED_CONTRACT_TARGET_PRICE_EXCEEDED',
    defaultMessage:
      'The order exceeds the amount remaining on this RapidRed™ Card.',
  },
  MPOWER_CONTRACT_EXPIRED: {
    id: 'MPOWER_CONTRACT_EXPIRED',
    defaultMessage: 'The MPower Payment Account is expired.',
  },
  RAPIDRED_CONTRACT_EXPIRED: {
    id: 'RAPIDRED_CONTRACT_EXPIRED',
    defaultMessage: 'The RapidRed™ Card is expired.',
  },
  PAYMENT_PO_NUMBER_EMPTY: {
    id: 'PAYMENT_PO_NUMBER_EMPTY',
    defaultMessage: 'PO Number required',
  },
  //Message returned as endpoint error. Do not modify or delete key.
  PAYMENT_PO_NUMBER_MASKING_ERROR: {
    id: 'PAYMENT_PO_NUMBER_MASKING_ERROR ',
    defaultMessage: 'PO Number does not match the required format',
  },
  PAYMENT_PO_NUMBER_MAX_25_CHARACTERS: {
    id: 'PAYMENT_PO_NUMBER_MAX_25_CHARACTERS ',
    defaultMessage: 'invalid: Must be 25 characters max',
  },
  PAYMENT_REQUISITION_NUMBER_EMPTY: {
    id: 'PAYMENT_REQUISITION_NUMBER_EMPTY',
    defaultMessage: 'Requisition Number required',
  },
  //Message returned as endpoint error. Do not modify or delete key.
  PAYMENT_REQUISITION_NUMBER_MASKING_ERROR: {
    id: 'PAYMENT_REQUISITION_NUMBER_MASKING_ERROR',
    defaultMessage: 'Requisition Number does not match the required format',
  },
  PAYMENT_DEALICENSE_NUMBER_EMPTY: {
    id: 'PAYMENT_DEALICENSE_NUMBER_EMPTY',
    defaultMessage: 'Valid DEA License Number is required',
  },
  PAYMENT_DEALICENSE_NUMBER_MASKING_ERROR: {
    id: 'PAYMENT_DEALICENSE_NUMBER_MASKING_ERROR',
    defaultMessage: 'Valid DEA License Number is required',
  },
  REQUIRED_CHARGEBACK_CODES_NOT_PRESENT: {
    id: 'REQUIRED_CHARGEBACK_CODES_NOT_PRESENT',
    defaultMessage: 'Required chargeback codes not present',
  },
  PAYMENT_CHARGEBACK_CODE_MASKING_ERROR: {
    id: 'PAYMENT_CHARGEBACK_CODE_MASKING_ERROR',
    defaultMessage: 'Chargeback code does not match the required format',
  },
  CREDIT_CARD_EXPIRED: {
    id: 'CREDIT_CARD_EXPIRED',
    defaultMessage: 'Credit Card Expired',
  },
  CREDIT_CARD_EXPIRED_ELAVON: {
    id: 'CREDIT_CARD_EXPIRED_ELAVON',
    defaultMessage:
      'The Credit Card Expiration Date supplied in the authorization request appears to be invalid',
  },
  CREDIT_CARD_AUTHORIZATION_NOT_SUCCESSFUL: {
    id: 'CREDIT_CARD_AUTHORIZATION_NOT_SUCCESSFUL',
    defaultMessage:
      'returning the following error: "Authorization not successful"',
  },
  INVALID_CREDIT_CARD_NUMBER: {
    id: 'INVALID_CREDIT_CARD_NUMBER',
    defaultMessage: 'Valid CreditCard Number is required',
  },
  INVALID_CREDIT_CARD_NUMBER_ELAVON: {
    id: 'INVALID_CREDIT_CARD_NUMBER_ELAVON',
    defaultMessage:
      'The Credit Card Number supplied in the authorization request appears to be invalid',
  },
  FIELD_ERROR_CONDITION: {
    id: 'FIELD_ERROR_CONDITION',
    defaultMessage: '{field} is {condition}',
  },
  FIELD_ERROR_CONDITION_ONLY: {
    id: 'FIELD_ERROR_CONDITION_ONLY',
    defaultMessage: '{condition}',
  },
  FIELD_ERROR_CONDITION_BOTH: {
    id: 'FIELD_ERROR_CONDITION_BOTH',
    defaultMessage: '{field} must be {condition}',
  },
  DEA_LICENSE_NUMBER: {
    id: 'DEA_LICENSE_NUMBER',
    defaultMessage: 'DEA License Number',
  },
  CART_EDIT_SHIPPING_ADDRESS_FORM_TITLE: {
    id: 'CART_EDIT_SHIPPING_ADDRESS_FORM_TITLE',
    defaultMessage: 'Edit Shipping Address',
  },
  CART_EDIT_SHIPPING_ADDRESS_DELAY_WARNING: {
    id: 'CART_EDIT_SHIPPING_ADDRESS_DELAY_WARNING',
    defaultMessage: 'Editing any of these fields will delay your order.',
  },
  CART_EDIT_SHIPPING_ADDRESS_MESSAGE: {
    id: 'CART_EDIT_SHIPPING_ADDRESS_MESSAGE',
    defaultMessage:
      'Changes will only apply to this order. To permanently edit this address, please contact customer service.',
  },
  COUNTRY: { id: 'COUNTRY', defaultMessage: 'Location' },
  STATE: { id: 'STATE', defaultMessage: 'State' },
  POSTAL_CODE: { id: 'POSTAL_CODE', defaultMessage: 'Postal Code' },
  SAVE: { id: 'SAVE', defaultMessage: 'Save' },
  SAVE_INSTRUCTIONS: {
    id: 'SAVE_INSTRUCTIONS',
    defaultMessage: 'Save Instructions',
  },
  STANDARD_DELIVERY: {
    id: 'STANDARD_DELIVERY',
    defaultMessage: 'Standard Delivery',
  },
  NO_PREFERENCE_STANDARD_DELIVERY: {
    id: 'NO_PREFERENCE_STANDARD_DELIVERY',
    defaultMessage: 'No Preference/Standard Delivery',
  },
  INTERNATIONAL_OCEAN_TRUCK_FREIGHT: {
    id: 'INTERNATIONAL_OCEAN_TRUCK_FREIGHT',
    defaultMessage: 'International Ocean/Truck Freight',
  },
  INTERNATIONAL_AIR_FREIGHT: {
    id: 'INTERNATIONAL_AIR_FREIGHT',
    defaultMessage: 'International Air Freight',
  },
  TRUCK: { id: 'TRUCK', defaultMessage: 'Truck' },
  FEDEX_GROUND_5_TO_7_BUSINESS_DAYS: {
    id: 'FEDEX_GROUND_5_TO_7_BUSINESS_DAYS',
    defaultMessage: 'FedEx Ground - 5 to 7 Business Days',
  },
  INTERNATIONAL_FEDEX: {
    id: 'INTERNATIONAL_FEDEX',
    defaultMessage: 'International FedEx',
  },
  FEDEX_STANDARD_OVERNIGHT_3_00_PM_NEXT_BUSINESS_DAY: {
    id: 'FEDEX_STANDARD_OVERNIGHT_3_00_PM_NEXT_BUSINESS_DAY',
    defaultMessage: 'FedEx Standard Overnight - 3:00pm Next Business Day',
  },
  FEDEX_SECOND_DAY_4_30_PM_IN_TWO_BUSINESS_DAYS: {
    id: 'FEDEX_SECOND_DAY_4_30_PM_IN_TWO_BUSINESS_DAYS',
    defaultMessage: 'FedEx Second Day - 4:30pm in two Business Days',
  },
  LOCAL_DELIVERY_NOT_AVAILABLE_IN_MOST_AREAS: {
    id: 'LOCAL_DELIVERY_NOT_AVAILABLE_IN_MOST_AREAS',
    defaultMessage: 'Local Delivery (Not Available in Most Areas)',
  },
  FEDEX_PRIORITY_OVERNIGHT_10_30_AM_NEXT_BUSINESS_DAY: {
    id: 'FEDEX_PRIORITY_OVERNIGHT_10_30_AM_NEXT_BUSINESS_DAY',
    defaultMessage: 'FedEx Priority Overnight - 10:30am Next Business Day',
  },
  INTERNATIONAL_NO_PREFERENCE_BEST_WAY: {
    id: 'INTERNATIONAL_NO_PREFERENCE_BEST_WAY',
    defaultMessage: 'International No Preference/Best Way',
  },
  UPS_GROUND_5_TO_7_BUSINESS_DAYS: {
    id: 'UPS_GROUND_5_TO_7_BUSINESS_DAYS',
    defaultMessage: 'UPS Ground - 5 to 7 Business Days',
  },
  SHIPPING_INFORMATION: {
    id: 'SHIPPING_INFORMATION',
    defaultMessage: 'Shipping Information',
  },
  SHIP_TO: { id: 'SHIP_TO', defaultMessage: 'Ship To' },
  SHIPTO_CHECKOUT_MESSAGE: {
    id: 'SHIPTO_CHECKOUT_MESSAGE',
    defaultMessage: 'This order may arrive in multiple shipments.',
  },
  REQUIRED_FIELDS: { id: 'REQUIRED_FIELDS', defaultMessage: 'Required Fields' },
  RECIPIENT_INFO: { id: 'RECIPIENT_INFO', defaultMessage: 'Recipient Info' },
  PROFILE_SHIPPING_ADDRESS_ATTENTION_TO: {
    id: 'PROFILE_SHIPPING_ADDRESS_ATTENTION_TO',
    defaultMessage: 'Attention To',
  },
  CART_DEPARTMENT: { id: 'CART_DEPARTMENT', defaultMessage: 'Department' },
  CART_EMAIL: { id: 'CART_EMAIL', defaultMessage: 'Email' },
  PHONE_NUMBER: { id: 'PHONE_NUMBER', defaultMessage: 'Phone Number' },
  RETURN_TO_CART: { id: 'RETURN_TO_CART', defaultMessage: 'Return to Cart' },
  CART_EXT: { id: 'CART_EXT', defaultMessage: 'Extension' },
  CART_MOBILE_PHONE: {
    id: 'CART_MOBILE_PHONE',
    defaultMessage: 'Mobile Phone',
  },
  SHIP_DIRECTLY_FROM_CUSTOM_PRODUCT: {
    id: 'SHIP_DIRECTLY_FROM_CUSTOM_PRODUCT',
    defaultMessage:
      'Ship custom products (e.g. oligos, qPCR probes & peptides) directly from {shipFrom}',
  },
  CART_ADDRESS_SHIPPING_ADDRESS: {
    id: 'CART_ADDRESS_SHIPPING_ADDRESS',
    defaultMessage: 'Shipping Address',
  },
  SELECT_ADDRESS: { id: 'SELECT_ADDRESS', defaultMessage: 'Select Address' },
  FIX_ERRORS_TO_CONTINUE: {
    id: 'FIX_ERRORS_TO_CONTINUE',
    defaultMessage: 'Fix {numErrors} error(s) to continue',
  },
  EDIT: { id: 'EDIT', defaultMessage: 'Edit' },
  CART_MOBILE: { id: 'CART_MOBILE', defaultMessage: 'Mobile' },
  CART_FAX: { id: 'CART_FAX', defaultMessage: 'Fax' },
  ADDITIONAL_SHIPPING_DETAILS: {
    id: 'ADDITIONAL_SHIPPING_DETAILS',
    defaultMessage: 'Additional Shipping Details',
  },
  SHIPPING_DATE: { id: 'SHIPPING_DATE', defaultMessage: 'Shipping Date' },
  FUTURE_DELIVERY_DATE: {
    id: 'FUTURE_DELIVERY_DATE',
    defaultMessage: 'Delivery Date',
  },
  SELECT_SHIPPING_DATE: {
    id: 'SELECT_SHIPPING_DATE',
    defaultMessage: 'Select Shipping Date',
  },
  FUTURE_DELIVERY_DATE_SELECT: {
    id: 'FUTURE_DELIVERY_DATE_SELECT',
    defaultMessage: 'Select Delivery Date',
  },
  FUTURE_DELIVERY_DATE_FOOTER: {
    id: 'FUTURE_DELIVERY_DATE_FOOTER',
    defaultMessage:
      'We will make every effort to accommodate your date request. However, your order will be confirmed according to the availability of the product.',
  },
  PACKING_SLIP_NOTE_DESC: {
    id: 'PACKING_SLIP_NOTE_DESC',
    defaultMessage: 'Packing Slip Notes are printed on the packing slip',
  },
  PACKING_SLIP_NOTE_SUB_TEXT: {
    id: 'PACKING_SLIP_NOTE_SUB_TEXT',
    defaultMessage: 'Add a note to the packing slip',
  },
  SHARE_ORDER: { id: 'SHARE_ORDER', defaultMessage: 'Share Order' },
  CART_CC: {
    id: 'CART_CC',
    defaultMessage: 'CC (Separate multiple emails with commas)',
  },
  ADD_CSR_INSTRUCTIONS: {
    id: 'ADD_CSR_INSTRUCTIONS',
    defaultMessage: 'Add Instructions',
  },
  EDIT_CSR_INSTRUCTIONS: {
    id: 'EDIT_CSR_INSTRUCTIONS',
    defaultMessage: 'Edit Instructions',
  },
  CSR_HELP_TEXT: {
    id: 'CSR_HELP_TEXT',
    defaultMessage:
      'Reminder: Special Order Notes are reviewed by a Customer\n      Service Representative and will block your order from\n      being automatically processed, causing a delay in your\n      order.',
  },
  EXPEDIATED_SERVICE_DESC: {
    id: 'EXPEDIATED_SERVICE_DESC',
    defaultMessage:
      'Expedited service only applies to certain items shipping from a limited set of US locations.',
  },
  FUTURE_DELIVERY_DATE_INFO: {
    id: 'FUTURE_DELIVERY_DATE_INFO',
    defaultMessage: 'The delivery date only applies to prepack items.',
  },
  CSR_WARNING_TITLE: {
    id: 'CSR_WARNING_TITLE',
    defaultMessage: 'Customer Service Instructions',
  },
  CSR_WARNING_MESSAGE: {
    id: 'CSR_WARNING_MESSAGE',
    defaultMessage:
      'Special Order Notes are reviewed by a Customer Service Representative\n      and will block your order from being automatically processed, causing\n      a delay in your order. For collect shipments, please add your collect\n      account number(s) and carrier name(s) in the Special Order Note field.',
  },
  EDIT_CONTACT_INFORMATION: {
    id: 'EDIT_CONTACT_INFORMATION',
    defaultMessage: 'Edit Contact Information',
  },
  CART_EDIT_ORDERING_PARTY_ADDRESS_MESSAGE: {
    id: 'CART_EDIT_ORDERING_PARTY_ADDRESS_MESSAGE',
    defaultMessage:
      'Changes will only apply to this order. To permanently update this information, edit {addressWithLink}',
  },
  CART_EDIT_ORDERING_PARTY_ADDRESS_LINK: {
    id: 'CART_EDIT_ORDERING_PARTY_ADDRESS_LINK',
    defaultMessage: 'your Profile Information',
  },
  CONTACT_NAME: { id: 'CONTACT_NAME', defaultMessage: 'Contact Name' },
  EMAIL: { id: 'EMAIL', defaultMessage: 'Email' },
  CART_PHONE: { id: 'CART_PHONE', defaultMessage: 'Phone' },
  YOUR_REFERENCE: { id: 'YOUR_REFERENCE', defaultMessage: 'Your Reference' },
  OPTIONAL: { id: 'OPTIONAL', defaultMessage: 'Optional' },
  CART_ITEM_REFERENCE: {
    id: 'CART_ITEM_REFERENCE',
    defaultMessage: 'Reference',
  },
  ITEMS_IN_YOUR_CART: {
    id: 'ITEMS_IN_YOUR_CART',
    defaultMessage: 'Items in Your Cart',
  },
  EDIT_CART_LINK: { id: 'EDIT_CART_LINK', defaultMessage: 'Edit Cart' },
  PLEASE_CHOOSE_YOUR_PREFERRED_SHIPPING_METHOD: {
    id: 'PLEASE_CHOOSE_YOUR_PREFERRED_SHIPPING_METHOD',
    defaultMessage:
      '  Please choose your preferred shipping method(s). Some items may only be eligible for ground shipping.',
  },
  ITEMS_IN_YOUR_CART_SHORT: {
    id: 'ITEMS_IN_YOUR_CART_SHORT',
    defaultMessage: 'Cart Items',
  },
  MARKETPLACE_TRUSTED_SELLER: {
    id: 'MARKETPLACE_TRUSTED_SELLER',
    defaultMessage:
      'You are purchasing these products from trusted third-party sellers.',
  },
  order_field_label_to_date: {
    id: 'order_field_label_to_date',
    defaultMessage: 'To Date',
  },
  order_field_label_from_date: {
    id: 'order_field_label_from_date',
    defaultMessage: 'From Date',
  },
  order_field_label_order_date: {
    id: 'order_field_label_order_date',
    defaultMessage: 'Order Date',
  },
  SHIPPED_SOLD_BY: {
    id: 'SHIPPED_SOLD_BY',
    defaultMessage: 'Shipped & Sold By',
  },
  PRICE: { id: 'PRICE', defaultMessage: 'Price' },
  QUANTITY: { id: 'QUANTITY', defaultMessage: 'Quantity' },
  PRICE_PRESENTED_TO_CUSTOMER: {
    id: 'PRICE_PRESENTED_TO_CUSTOMER',
    defaultMessage: 'Price Presented to Customer',
  },
  DIGITAL_PRODUCT_INSTRUMENT_SERIAL_NUMBER: {
    id: 'DIGITAL_PRODUCT_INSTRUMENT_SERIAL_NUMBER',
    defaultMessage: 'Your instrument serial number(s):',
  },
  THIRD_PARTY_TOS: {
    id: 'THIRD_PARTY_TOS',
    defaultMessage: 'Aldrich Partner Product Terms of Service',
  },
  HIDE_DETAILS: { id: 'HIDE_DETAILS', defaultMessage: 'Hide Details' },
  VIEW_DETAILS: { id: 'VIEW_DETAILS', defaultMessage: 'View Details' },
  SHOW_DETAILS: { id: 'SHOW_DETAILS', defaultMessage: 'Show Details' },
  CHECKOUT_ITEM_PRICE: { id: 'CHECKOUT_ITEM_PRICE', defaultMessage: 'Price' },
  CHECKOUT_ITEM_QUANTITY: {
    id: 'CHECKOUT_ITEM_QUANTITY',
    defaultMessage: 'Quantity',
  },
  CHECKOUT_ITEM_TOTAL: { id: 'CHECKOUT_ITEM_TOTAL', defaultMessage: 'Total' },
  CART_EDIT_BILLING_ADDRESS_FORM_TITLE: {
    id: 'CART_EDIT_BILLING_ADDRESS_FORM_TITLE',
    defaultMessage: 'Edit Billing Address',
  },
  CART_EDIT_BILLING_ADDRESS_MESSAGE: {
    id: 'CART_EDIT_BILLING_ADDRESS_MESSAGE',
    defaultMessage:
      'Changes will only apply to this order. To permanently edit this address, please contact customer service.',
  },
  CART_ATTENTION: { id: 'CART_ATTENTION', defaultMessage: 'Attention' },
  DEPARTMENT: { id: 'DEPARTMENT', defaultMessage: 'Department' },
  BUILDING_ROOM: { id: 'BUILDING_ROOM', defaultMessage: 'Building / Room' },
  PO_BOX: { id: 'PO_BOX', defaultMessage: 'PO Box' },
  YEAR: { id: 'YEAR', defaultMessage: 'Year' },
  MONTH: { id: 'MONTH', defaultMessage: 'Month' },
  INVALID_CREDIT_CARD_TYPE: {
    id: 'INVALID_CREDIT_CARD_TYPE',
    defaultMessage: 'Invalid Credit Card Type. Please use {cards}',
  },
  BILLING_INFORMATION: {
    id: 'BILLING_INFORMATION',
    defaultMessage: 'Billing Information',
  },
  BILL_TO: { id: 'BILL_TO', defaultMessage: 'Bill To' },
  EMD_CART_CHECKOUT_CHOOSE_PAYMENT_MESSAGE: {
    id: 'EMD_CART_CHECKOUT_CHOOSE_PAYMENT_MESSAGE',
    defaultMessage:
      'Please choose a payment method. This EMD Millipore order will be charged individually.',
  },
  CART_PAYMENT_METHOD: {
    id: 'CART_PAYMENT_METHOD',
    defaultMessage: 'Payment Method',
  },
  NAME_ON_CARD: { id: 'NAME_ON_CARD', defaultMessage: 'Name on Card' },
  CREDIT_CARD_NUMBER: {
    id: 'CREDIT_CARD_NUMBER',
    defaultMessage: 'Credit Card Number',
  },
  EXPIRATION: { id: 'EXPIRATION', defaultMessage: 'Expiration' },
  SECURITY_CODE: { id: 'SECURITY_CODE', defaultMessage: 'Security Code' },
  ADD_NEW_CREDITCARD_HINT: {
    id: 'ADD_NEW_CREDITCARD_HINT',
    defaultMessage:
      'The new Credit Card is applied to this transaction only. To make this Credit Card your default, add it to the Payment Method in your Profile.',
  },
  PIN: { id: 'PIN', defaultMessage: 'PIN' },
  PO_ORDER_TOTAL_GREATER_THAN_AVAILABLE_CREDIT: {
    id: 'ORDER_TOTAL_GREATER_THAN_AVAILABLE_CREDIT',
    defaultMessage: 'Order Total greater than available credit',
  },
  CART_BILLING_ADDRESS: {
    id: 'CART_BILLING_ADDRESS',
    defaultMessage: 'Billing Address',
  },
  CART_ADDRESS_LOCKED: { id: 'CART_ADDRESS_LOCKED', defaultMessage: 'Locked' },
  CART_MERCHANT_LOCATION: {
    id: 'CART_MERCHANT_LOCATION',
    defaultMessage: 'Merchant Location',
  },
  MARKETPLACE_MERCHANT_LOCATION_TEXT: {
    id: 'MARKETPLACE_MERCHANT_LOCATION_TEXT',
    defaultMessage: 'All Products ship from United States',
  },
  PO_NUMBER_DIALOG_TITLE: {
    id: 'PO_NUMBER_DIALOG_TITLE',
    defaultMessage: 'Purchase Order (PO) Number',
  },
  PO_NUMBER_DEFINITION: {
    id: 'PO_NUMBER_DEFINITION',
    defaultMessage:
      'The Purchase Order Number or PO Number is a string of numbers or combination of numbers and letters that you can use to track, identify or reference your order.',
  },
  NO_PO_NUMBER_ADVICE: {
    id: 'NO_PO_NUMBER_ADVICE',
    defaultMessage:
      'If you don’t have a Purchase Order Number provided by your organization you can use any combination of numbers and letters that help you to identify this order.',
  },
  //Message returned as endpoint error. Do not modify or delete key.
  USER_NOT_AUTHORIZED_OR_TOKEN_EXPIRED: {
    id: 'USER_NOT_AUTHORIZED_OR_TOKEN_EXPIRED',
    defaultMessage:
      'You are not authorized or Your user session has expired. Sign in to continue.',
  },
  EMD_MILLIPORE_CHECKOUT: {
    id: 'EMD_MILLIPORE_CHECKOUT',
    defaultMessage: 'EMD Millipore Checkout',
  },
  MERCK_CHEMICALS_CHECKOUT: {
    id: 'MERCK_CHEMICALS_CHECKOUT',
    defaultMessage: 'Merck Chemicals Checkout',
  },
  KOREAN_VALUE_ADDED_TAX_ACT: {
    id: 'KOREAN_VALUE_ADDED_TAX_ACT',
    defaultMessage:
      'In accordance with Korean Value Added Tax Act, we cannot issue the amended tax invoice to change the transaction date of original tax invoice.',
  },
  REQUEST: {
    id: 'REQUEST',
    defaultMessage: 'Request {customerRequestId} {organizationName}',
  },
  THERE_ARE_ERRORS: {
    id: 'THERE_ARE_ERRORS',
    defaultMessage: 'There are {count} errors on the page. {message}',
  },
  THERE_IS_ERROR: {
    id: 'THERE_IS_ERROR',
    defaultMessage: 'There is {count} error on the page. {message}',
  },
  BACK_TO_DEALER_REQUESTS: {
    id: 'BACK_TO_DEALER_REQUESTS',
    defaultMessage: 'Back to Dealer Requests',
  },
  MARKETPLACE_CHECKOUT_MESSAGE: {
    id: 'MARKETPLACE_CHECKOUT_MESSAGE',
    defaultMessage:
      'Please confirm the billing and shipping information for the products that you are purchasing from Marketplace sellers.  You will receive a unique order number for these orders.',
  },
  EMD_MILLIPORE_CHECKOUT_MESSAGE: {
    id: 'EMD_MILLIPORE_CHECKOUT_MESSAGE',
    defaultMessage:
      'Please confirm the billing and shipping information for the products you are purchasing from EMD Millipore. You will receive a unique order number for this order.',
  },
  MERCK_CHEMICALS_CHECKOUT_MESSAGE: {
    id: 'MERCK_CHEMICALS_CHECKOUT_MESSAGE',
    defaultMessage:
      'Please confirm your billing and shipping information for this order. Please note that you are purchasing Merck Chemicals products and you will receive VAT invoice from Merck Chemicals.',
  },
  MERCK_SIGMA_CHECKOUT_MESSAGE: {
    id: 'MERCK_SIGMA_CHECKOUT_MESSAGE',
    defaultMessage:
      'Please confirm your billing and shipping information for this order. Please note that you are purchasing Sigma-Aldrich products and you will receive VAT invoice from Sigma-Aldrich.',
  },
  PRICE_NOT_CALCULATED_FULL: {
    id: 'PRICE_NOT_CALCULATED_FULL',
    defaultMessage:
      'Price cannot be calculated at this time, but your order can still be placed.',
  },
  CUSTOMER_INFORMATION: {
    id: 'CUSTOMER_INFORMATION',
    defaultMessage: 'Customer Information',
  },
  CART_ADDRESS_DISPLAY_EXTENSION: {
    id: 'CART_ADDRESS_DISPLAY_EXTENSION',
    defaultMessage: 'Ext. {extension}',
  },
  CART_ADDRESS_DISPLAY_MOBILE: {
    id: 'CART_ADDRESS_DISPLAY_MOBILE',
    defaultMessage: '{mobile} (Mobile)',
  },
  CONFIRM_CART_ITEMS: {
    id: 'CONFIRM_CART_ITEMS',
    defaultMessage: 'Confirm the number of items in your cart',
  },
  CONFIRM_CART_ITEMS_MSG: {
    id: 'CONFIRM_CART_ITEMS_MSG',
    defaultMessage:
      'The number of items in your cart has changed. This occurs when multiple users share the same account credentials or when a user add products to a cart on multiple devices. Please confirm that the number of items in your cart are correct before checking out.',
  },
  CONTINUE: { id: 'CONTINUE', defaultMessage: 'Continue' },
  REJECT_ORDER: { id: 'REJECT_ORDER', defaultMessage: 'Reject Order' },
  REASON_ORDER_REJECTED: {
    id: 'REASON_ORDER_REJECTED',
    defaultMessage: 'Reason Order Rejected',
  },
  CANCEL: { id: 'CANCEL', defaultMessage: 'Cancel' },
  SUBMIT: { id: 'SUBMIT', defaultMessage: 'Submit' },
  AGREE: { id: 'AGREE', defaultMessage: 'Agree' },
  REJECT_ALL_REQUESTS_TITLE: {
    id: 'REJECT_ALL_REQUESTS_TITLE',
    defaultMessage: 'Reject All Request',
  },
  REJECT_REQUEST: { id: 'REJECT_REQUEST', defaultMessage: 'Reject Request' },
  REASON_FOR_REJECTING_REQUEST: {
    id: 'REASON_FOR_REJECTING_REQUEST',
    defaultMessage: 'Reason for rejecting the request',
  },
  WILL_FULFILL_REQUEST_FROM_LOCAL_STOCK: {
    id: 'WILL_FULFILL_REQUEST_FROM_LOCAL_STOCK',
    defaultMessage: 'Will fulfill request from local stock',
  },
  WILL_NOT_FULFILL_REQUEST: {
    id: 'WILL_NOT_FULFILL_REQUEST',
    defaultMessage: 'Reject. Will not fulfill this request.',
  },
  REASON_FOR_REJECTION_COMMENTS: {
    id: 'REASON_FOR_REJECTION_COMMENTS',
    defaultMessage: 'Reason for Rejection',
  },
  SAVE_CART: { id: 'SAVE_CART', defaultMessage: 'Save Cart' },
  SAVE_MARKETPLACE_CART: {
    id: 'SAVE_MARKETPLACE_CART',
    defaultMessage: 'Save Marketplace Cart',
  },
  SAVE_CART_NAME: { id: 'SAVE_CART_NAME', defaultMessage: 'Cart Name' },
  SAVE_CART_COMMENT: {
    id: 'SAVE_CART_COMMENT',
    defaultMessage: 'Cart Comment',
  },
  SHOPPING_CART_SUMMARY: {
    id: 'SHOPPING_CART_SUMMARY',
    defaultMessage: 'SUMMARY',
  },
  DELIVERY_DELAY_ACKNOWLEDGEMENT: {
    id: 'DELIVERY_DELAY_ACKNOWLEDGEMENT',
    defaultMessage:
      'One or more items in this cart could be delayed due to compliance or shipping regulations or may have limited quality and safety documentation. Once ordered, the noted item(s) may not be canceled or returned. I have read and accept the limitations related to these products.',
  },
  SUBMIT_FOR_APPROVAL: {
    id: 'SUBMIT_FOR_APPROVAL',
    defaultMessage: 'Submit for Approval',
  },
  CONSOLIDATE_ORDER: {
    id: 'CONSOLIDATE_ORDER',
    defaultMessage: 'Consolidate Order',
  },
  SELECT_DEALER: { id: 'SELECT_DEALER', defaultMessage: 'Select Dealer' },
  APPROVE_ORDER: { id: 'APPROVE_ORDER', defaultMessage: 'Approve Order' },
  REJECT_THIS_REQUEST: {
    id: 'REJECT_THIS_REQUEST',
    defaultMessage: 'Reject This Request Only',
  },
  REJECT_ALL_REQUESTS: {
    id: 'REJECT_ALL_REQUESTS',
    defaultMessage: 'Reject All Requests From Customer',
  },
  SUMMARY_BY_PROCEEDING: {
    id: 'SUMMARY_BY_PROCEEDING',
    defaultMessage:
      'Non-Sigma-Aldrich products within your order will be charged individually from your preferred payment method. Each payment is referenced to the PO Number which you can find in your invoice.',
  },
  CART_SUMMARY_TERMS_INTRO_TEXT: {
    id: 'CART_SUMMARY_TERMS_INTRO_TEXT',
    defaultMessage:
      'By submitting this order, I accept {companyName}’s {privacyPolicyLink} and {conditionOfSaleLink}.',
  },
  CART_SUMMARY_TERMS_INTRO: {
    id: 'CART_SUMMARY_TERMS_INTRO',
    defaultMessage: 'By submitting this order, I accept {companyName}’s',
  },
  CART_POLICY_AND: { id: 'CART_POLICY_AND', defaultMessage: 'and' },
  EMD_CART_SUMMARY_TERMS_INTRO: {
    id: 'EMD_CART_SUMMARY_TERMS_INTRO',
    defaultMessage: 'By submitting this order, I accept EMD Millipore’s',
  },
  MERCK_CART_SUMMARY_TERMS_INTRO: {
    id: 'MERCK_CART_SUMMARY_TERMS_INTRO',
    defaultMessage: 'By submitting this order, I accept Merck’s',
  },
  MERCK_CHEMICALS_CART_SUMMARY_TERMS_INTRO: {
    id: 'MERCK_CHEMICALS_CART_SUMMARY_TERMS_INTRO',
    defaultMessage: 'By submitting this order, I accept Merck Chemicals’',
  },
  CART_SUMMARY_MPARTNER_TERMS_INTRO_1: {
    id: 'CART_SUMMARY_MPARTNER_TERMS_INTRO_1',
    defaultMessage: 'By submitting this order I accept MilliporeSigma’s',
  },
  CART_SUMMARY_MPARTNER_TERMS_INTRO_2: {
    id: 'CART_SUMMARY_MPARTNER_TERMS_INTRO_2',
    defaultMessage: 'and the applicable',
  },
  CART_SUMMARY_MPARTNER_TERMS_INTRO_3: {
    id: 'CART_SUMMARY_MPARTNER_TERMS_INTRO_3',
    defaultMessage: 'of the marketplace seller(s).',
  },
  DEALER_CART_NOTICE: {
    id: 'DEALER_CART_NOTICE',
    defaultMessage:
      'Notice: Upon acceptance of your order by the dealer, you will enter into contract for sale directly with the dealer. By no means will {companyName} and its affiliates be party of such transaction nor take any responsibility thereof.',
  },
  SELECT: { id: 'SELECT', defaultMessage: 'Select' },
  CART_HEADER_ERROR: {
    id: 'CART_HEADER_ERROR',
    defaultMessage:
      'One or more of your items does not meet the necessary purchase requirements. Please update your cart to proceed.',
  },
  CLEAR_PROMO_CODE: {
    id: 'CLEAR_PROMO_CODE',
    defaultMessage: 'Clear Promo Code',
  },
  DIGITAL_PRODUCT_KEY_ALREADY_GENERATED: {
    id: 'DIGITAL_PRODUCT_KEY_ALREADY_GENERATED',
    defaultMessage: 'This serial number is already in use.',
  },
  SELLER_SOLD_BY: {
    id: 'SELLER_SOLD_BY',
    defaultMessage: 'Shipped & Sold By {Brand}',
  },
  PRESS_UPDATE_CART_TO_CONTINUE: {
    id: 'PRESS_UPDATE_CART_TO_CONTINUE',
    defaultMessage:
      "Please press the 'Update Cart' button to remove this item from your cart.",
  },
  CART_ITEM_DEA_LICENSE_NUMBER_INFO: {
    id: 'CART_ITEM_DEA_LICENSE_NUMBER_INFO',
    defaultMessage: 'DEA License Number required at checkout.',
  },
  ZERO_PRICING_ITEM_LEVEL_ERROR: {
    id: 'ZERO_PRICING_ITEM_LEVEL_ERROR',
    defaultMessage: 'This item is unpriced and is not supported.',
  },
  THIS_PRODUCT_HAS_BEEN_DISCONTINUED: {
    id: 'THIS_PRODUCT_HAS_BEEN_DISCONTINUED',
    defaultMessage: 'Product {productNumber} has been discontinued.',
  },
  PRODUCT_NOT_AVAILABLE_FOR_PURCHASE: {
    id: 'PRODUCT_NOT_AVAILABLE_FOR_PURCHASE',
    defaultMessage:
      'Product {productNumber} is currently not available for purchase.',
  },
  RESTORE_ITEM_TO_CART_SHORT: {
    id: 'RESTORE_ITEM_TO_CART_SHORT',
    defaultMessage: 'Restore',
  },
  SELLER_ESTIMATED_SHIPPING_TITLE: {
    id: 'SELLER_ESTIMATED_SHIPPING_TITLE',
    defaultMessage: 'Estimated to Ship: ',
  },
  MIN_N_QUANTITY: { id: 'MIN_N_QUANTITY', defaultMessage: 'Minimum {min}' },
  MAX_QUANTITY: { id: 'MAX_QUANTITY', defaultMessage: 'Maximum {max}' },
  CART_ITEM_TOTAL: { id: 'CART_ITEM_TOTAL', defaultMessage: 'Total' },
  DIGITAL_PRODUCT_SERIAL_NUMBER_INVALID: {
    id: 'DIGITAL_PRODUCT_SERIAL_NUMBER_INVALID',
    defaultMessage: 'Please provide a valid serial number.',
  },
  DIGITAL_PRODUCT_SERIAL_NUMBER_UNIQUE: {
    id: 'DIGITAL_PRODUCT_SERIAL_NUMBER_UNIQUE',
    defaultMessage: 'The Serial Number needs to be unique within your cart.',
  },
  DIGITAL_PRODUCT_SERIAL_NUMBER: {
    id: 'DIGITAL_PRODUCT_SERIAL_NUMBER',
    defaultMessage: 'Please enter your 10 digit instrument serial number:',
  },
  SINGLE_LOT_PREFERENCE: {
    id: 'SINGLE_LOT_PREFERENCE',
    defaultMessage: 'Lot Preference',
  },
  SINGLE_LOT_PREFERENCE_HEADER: {
    id: 'SINGLE_LOT_PREFERENCE_HEADER',
    defaultMessage: 'Single Lot Preference',
  },
  SINGLE_LOT_PREFERENCE_INFO: {
    id: 'SINGLE_LOT_PREFERENCE_INFO',
    defaultMessage: 'Selecting a single lot may cause delays with this order.',
  },
  CONTACT_TECHNICAL_SERVICE_WITH_QUESTIONS: {
    id: 'CONTACT_TECHNICAL_SERVICE_WITH_QUESTIONS',
    defaultMessage: 'Contact {link} with questions.',
  },
  TECHNICAL_SERVICE: {
    id: 'TECHNICAL_SERVICE',
    defaultMessage: 'Technical Service',
  },
  PRODUCT_DISCONTINUED_BUT_AVAILABLE_FOR_PURCHASE_CART: {
    id: 'PRODUCT_DISCONTINUED_BUT_AVAILABLE_FOR_PURCHASE_CART',
    defaultMessage:
      'Product {productNumber} has been discontinued but is still available for purchase.',
  },
  SELLER_SHIPPING_FROM_ADDRESS_DOES_NOT_EXISTS_IN_MIRAKL: {
    id: 'SELLER_SHIPPING_FROM_ADDRESS_DOES_NOT_EXISTS_IN_MIRAKL',
    defaultMessage:
      'Item {productNumber} is currently unavailable. Please contact {mailTo} team for more information.',
  },
  DIGITAL_PRODUCT_WARNING_MSG: {
    id: 'DIGITAL_PRODUCT_WARNING_MSG',
    defaultMessage:
      'For Prove Connect Items please enter your serial number(s) below.',
  },
  ITEMS_IN_YOUR_CART_SHORT_TITLE: {
    id: 'ITEMS_IN_YOUR_CART_SHORT_TITLE',
    defaultMessage: '{companyName} Cart',
  },
  CART_ITEMS: { id: 'CART_ITEMS', defaultMessage: 'Cart Items' },
  LOADING_SHOPPING_CART: {
    id: 'LOADING_SHOPPING_CART',
    defaultMessage: 'Loading Shopping Cart',
  },
  CART_CONTAINS_DISCONTINUED_PRODUCT: {
    id: 'CART_CONTAINS_DISCONTINUED_PRODUCT',
    defaultMessage:
      'Your cart contains a discontinued product. Please remove this product to continue.',
  },
  CART_CONTAINS_OUTOFSTOCK_PRODUCT: {
    id: 'CART_CONTAINS_OUTOFSTOCK_PRODUCT',
    defaultMessage:
      'Your cart contains a out of stock product. Please remove this product to continue.',
  },
  YOUR_MARKETPLACE_CART_IS_EMPTY: {
    id: 'YOUR_MARKETPLACE_CART_IS_EMPTY',
    defaultMessage: 'Your Marketplace Cart is Empty.',
  },
  YOUR_EMD_MILLIPORE_CART_IS_EMPTY: {
    id: 'YOUR_EMD_MILLIPORE_CART_IS_EMPTY',
    defaultMessage: 'Your EMD Millipore Cart is Empty.',
  },
  YOUR_MERCK_CHEMICALS_CART_IS_EMPTY: {
    id: 'YOUR_MERCK_CHEMICALS_CART_IS_EMPTY',
    defaultMessage: 'Your Merck Chemicals Cart is Empty.',
  },
  YOUR_CART_IS_EMPTY: {
    id: 'YOUR_CART_IS_EMPTY',
    defaultMessage: 'Your shopping cart is empty.',
  },
  CART_CONFIRM_BUYER_INFORMATION_TITLE: {
    id: 'CART_CONFIRM_BUYER_INFORMATION_TITLE',
    defaultMessage: 'Confirm Buyer Information',
  },
  SAVE_AND_REQUEST_QUOTE: {
    id: 'SAVE_AND_REQUEST_QUOTE',
    defaultMessage: 'Save',
  },
  MIN_ORDER_QUANTITY_ACKNOWLEDGEMENT: {
    id: 'MIN_ORDER_QUANTITY_ACKNOWLEDGEMENT',
    defaultMessage:
      'The minimum order quantity has not been met for highlighted material. Continuing without increasing the quantity will cause a manual review of your order and may delay shipment or result in a higher transportation charge.',
  },
  MIN_ORDER_QUANTITY_ACKNOWLEDGEMENT_REQUIRED: {
    id: 'MIN_ORDER_QUANTITY_ACKNOWLEDGEMENT_REQUIRED',
    defaultMessage: 'Acknowledgement Required',
  },
  B2B_CHECKOUT: { id: 'B2B_CHECKOUT', defaultMessage: 'Transfer Cart' },
  UPDATE_CART: { id: 'UPDATE_CART', defaultMessage: 'Update Cart' },
  CART_PROMO_POPOVER: {
    id: 'CART_PROMO_POPOVER',
    defaultMessage:
      'Applies to all cart items and overrides existing item promo codes.',
  },
  APPLY_PROMO_ALL_ITEMS: {
    id: 'APPLY_PROMO_ALL_ITEMS',
    defaultMessage: 'Apply promo code to all items',
  },
  SUBMITTING_CART: {
    id: 'SUBMITTING_CART',
    defaultMessage: 'Submitting Shopping Cart',
  },
  BEFORE_CONTINUING_TO_CHECKOUT: {
    id: 'BEFORE_CONTINUING_TO_CHECKOUT',
    defaultMessage: 'Before continuing to checkout',
  },
  IMPORTANT_NOTE: { id: 'IMPORTANT_NOTE', defaultMessage: 'Important Note' },
  THE_PRICE_THAT_YOU_ARE_CURRENTLY_VIEWING: {
    id: 'THE_PRICE_THAT_YOU_ARE_CURRENTLY_VIEWING',
    defaultMessage:
      'The price that you are currently viewing is the list price of {companyName}.',
  },
  IF_YOU_WOULD_LIKE_TO_SEE_THE_DEALERS_PRICE: {
    id: 'IF_YOU_WOULD_LIKE_TO_SEE_THE_DEALERS_PRICE',
    defaultMessage:
      'If you would like to see the dealer’s price, please wait until we review your account information or contact the dealer directly.',
  },
  PLEASE_CONTINUE_IF_YOU_WOULD_LIKE_TO_PROCEED: {
    id: 'PLEASE_CONTINUE_IF_YOU_WOULD_LIKE_TO_PROCEED',
    defaultMessage:
      'Please continue if you would like to proceed to checkout and confirm the final selling price with the dealer.',
  },
  CONTINUE_TO_CHECKOUT: {
    id: 'CONTINUE_TO_CHECKOUT',
    defaultMessage: 'Continue to Checkout',
  },
  CART_EXCLUDED_MATERIAL_NUMBER: {
    id: 'cart_excluded_material_number',
    defaultMessage:
      'Product {materialNumber} cannot be purchased since it is restricted by distributor agreements or customer contracts.',
  },
  THIS_PROCESS_IS_TAKING_LONGER: {
    id: 'THIS_PROCESS_IS_TAKING_LONGER',
    defaultMessage:
      'This process is taking longer than normal to complete. Please wait as we continue processing your request. You may also click "Cancel" to end the process and try again.',
  },
  BACK_TO_ALL_CARTS: {
    id: 'BACK_TO_ALL_CARTS',
    defaultMessage: 'Back to All Carts',
  },
  MARKETPLACE_CART: {
    id: 'MARKETPLACE_CART',
    defaultMessage: 'Marketplace Cart',
  },
  EMD_MILLIPORE_CART: {
    id: 'EMD_MILLIPORE_CART',
    defaultMessage: 'EMD Millipore Cart',
  },
  MERCK_CHEMICALS_CART: {
    id: 'MERCK_CHEMICALS_CART',
    defaultMessage: 'Merck Chemicals Cart',
  },
  CANNOT_ADD_TO_CART_DETAILS: {
    id: 'CANNOT_ADD_TO_CART_DETAILS',
    defaultMessage:
      'Your profile is currently configured to allow ordering for configurable items such as oligos and peptides but not regular inventory items. Although you can access pricing and availability for these products, they cannot be added to your shopping cart.',
  },
  CANNOT_ADD_OLIGO_TO_CART_DETAILS: {
    id: 'CANNOT_ADD_OLIGO_TO_CART_DETAILS',
    defaultMessage:
      'Your profile is configured to allow regular inventory items only. Configurable items such as oligos are not able to be added to your shopping cart.',
  },
  BLOCKED_PRODUCTS: {
    id: 'BLOCKED_PRODUCTS',
    defaultMessage:
      'The following item needs to be removed from your cart due to restrictions requested by your purchasing organization. Should you need these items, please contact your purchasing office.',
  },
  PRICING_DISCLAIMER_TITLE: {
    id: 'PRICING_DISCLAIMER_TITLE',
    defaultMessage: 'Pricing Subject to Change',
  },
  PRICING_DISCLAIMER_BODY_DTAQ: {
    id: 'PRICING_DISCLAIMER_BODY_DTAQ',
    defaultMessage:
      'The prices currently displayed are based on the settings of the selected dealer (not including tax). However, the final billing amount may differ due to handling fees, shipping charges, and other transaction conditions. If you have any questions, please contact the distributor in advance.',
  },
  PRICING_DISCLAIMER_BODY: {
    id: 'PRICING_DISCLAIMER_BODY',
    defaultMessage:
      'Upon submitting this cart, you will be asked to select a dealer to satisfy the order. Please note the list pricing reflected here may differ from pricing charged by the Dealer or {legalCompanyName} if you are a direct sales customer. Shipping, handling fees and taxes may also be included.',
  },
  ZERO_PRICING_ITEM_ERROR: {
    id: 'ZERO_PRICING_ITEM_ERROR',
    defaultMessage:
      'Unpriced items are not supported in most purchasing systems. Please remove any unpriced items before completing this punchout session.',
  },
  QUICK_ADD_ACTION: { id: 'QUICK_ADD_ACTION', defaultMessage: 'Quick Add' },
  UPDATING_SHOPPING_CART: {
    id: 'UPDATING_SHOPPING_CART',
    defaultMessage: 'Updating Shopping Cart',
  },
  QUICK_ADD_HEADING: { id: 'QUICK_ADD_HEADING', defaultMessage: 'Quick Add' },
  CART_DEA_LICENSE_NUMBER_MODAL_TITLE: {
    id: 'CART_DEA_LICENSE_NUMBER_MODAL_TITLE',
    defaultMessage: 'Required: DEA License Number',
  },
  CART_DEA_LICENSE_NUMBER_MODAL_INFO: {
    id: 'CART_DEA_LICENSE_NUMBER_MODAL_INFO',
    defaultMessage:
      "A DEA License Number is required to purchase one or more items. Input your license number and select 'Submit' to checkout.",
  },
  CART_DEA_LICENSE_NUMBER_LABEL: {
    id: 'CART_DEA_LICENSE_NUMBER_LABEL',
    defaultMessage: 'DEA License Number',
  },
  CART_DEA_LICENSE_NUMBER_MODAL_SUBMIT: {
    id: 'CART_DEA_LICENSE_NUMBER_MODAL_SUBMIT',
    defaultMessage: 'Submit',
  },
  ADDED_TO_CARTNAME_CART: {
    id: 'ADDED_TO_CARTNAME_CART',
    defaultMessage: 'Added to {cartName} Cart',
  },
  ABBREVIATED_QUANTITY_WITHOUT_DOT: {
    id: 'ABBREVIATED_QUANTITY_WITHOUT_DOT',
    defaultMessage: 'Qty',
  },
  CONTINUE_TO_QUICK_ADD: {
    id: 'CONTINUE_TO_QUICK_ADD',
    defaultMessage: 'Continue to Quick Add',
  },
  VIEW_CARTNAME_CART: {
    id: 'VIEW_CARTNAME_CART',
    defaultMessage: 'View {cartName} Cart',
  },
  PHONE: { id: 'PHONE', defaultMessage: 'Phone' },
  FAX: { id: 'FAX', defaultMessage: 'Fax' },
  BY_CUSTOMER_NUMBER: {
    id: 'BY_CUSTOMER_NUMBER',
    defaultMessage: 'by Customer Number',
  },
  SOLD_TO_COUNTRY: { id: 'SOLD_TO_COUNTRY', defaultMessage: 'Sold To Country' },
  CUSTOMER_NUMBER: { id: 'CUSTOMER_NUMBER', defaultMessage: 'Customer Number' },
  OR_KEYWORD_SEARCH: {
    id: 'OR_KEYWORD_SEARCH',
    defaultMessage: 'or Keyword Search',
  },
  KEYWORD_SEARCH_ERROR: {
    id: 'KEYWORD_SEARCH_ERROR',
    defaultMessage: 'Please choose at least 2 criteria for the keyword search.',
  },
  SEARCH_TERM: { id: 'SEARCH_TERM', defaultMessage: 'Search Term' },
  ORGANIZATION_NAME: {
    id: 'ORGANIZATION_NAME',
    defaultMessage: 'Organization Name',
  },
  STATE_PROVINCE_REGION: {
    id: 'STATE_PROVINCE_REGION',
    defaultMessage: 'State / Province / Region',
  },
  CONTACT_FIRST_NAME: {
    id: 'CONTACT_FIRST_NAME',
    defaultMessage: 'Contact First Name',
  },
  CONTACT_LAST_NAME: {
    id: 'CONTACT_LAST_NAME',
    defaultMessage: 'Contact Last Name',
  },
  NO_RECORD_FOUND: { id: 'NO_RECORD_FOUND', defaultMessage: 'No record found' },
  SOLD_TO: { id: 'SOLD_TO', defaultMessage: 'Sold To' },
  ACCOUNTS: { id: 'ACCOUNTS', defaultMessage: 'Accounts' },
  CUSTOMER_SERVICE_NOTE: {
    id: 'CUSTOMER_SERVICE_NOTE',
    defaultMessage: 'Customer Service Note',
  },
  SAVE_FOR_LATER: {
    id: 'SAVE_FOR_LATER',
    defaultMessage: 'Save for Later',
  },
  SAVED_FOR_LATER_SM: {
    id: 'SAVED_FOR_LATER_SM',
    defaultMessage: 'Saved for Later',
  },
  NO_SAVED_ITEMS: {
    id: 'NO_SAVED_ITEMS',
    defaultMessage: 'No saved items',
  },
  MOVE_TO_CART: {
    id: 'MOVE_TO_CART',
    defaultMessage: 'Move to Cart',
  },
  VIEW_ALL: {
    id: 'VIEW_ALL',
    defaultMessage: 'View All',
  },
  DELETE_SAVED_CART: {
    id: 'DELETE_SAVED_CART',
    defaultMessage: 'Delete',
  },
  SAVED_FOR_LATER: {
    id: 'SAVED_FOR_LATER',
    defaultMessage: 'SAVED FOR LATER',
  },
  SAVED_FOR_LATER_PLACEHOLDER: {
    id: 'SAVED_FOR_LATER_PLACEHOLDER',
    defaultMessage: `You currently have no items Saved for Later. {br}
      Click "Save for Later" on any eligible product in your cart to store it here for future purchasing.`,
  },
  NEW_CART: {
    id: 'NEW_CART',
    defaultMessage: `We've improved our checkout experience!`,
  },
  NEW_CART_LINK: {
    id: 'NEW_CART_LINK',
    defaultMessage: 'Switch to the new cart.',
  },
  CLASSIC_CART: {
    id: 'CLASSIC_CART',
    defaultMessage: `Thank you for trying our new checkout.`,
  },
  CLASSIC_CART_LINK: {
    id: 'CLASSIC_CART_LINK',
    defaultMessage: 'Return to the classic experience.',
  },
  CART_MIN_GLOBAL_ERROR: {
    id: 'CART_MIN_GLOBAL_ERROR',
    defaultMessage:
      ' Item {name} must be ordered with a minimum quantity of {minimumOrderQuantity}.',
  },
  CART_MIN_MULTIPLES_GLOBAL_ERROR: {
    id: 'CART_MIN_MULTIPLES_GLOBAL_ERROR',
    defaultMessage:
      ' Item {name} must be ordered in multiples of {minimumOrderQuantity}.',
  },
  BANK_NAME: {
    id: 'BANK_NAME',
    defaultMessage: 'Bank',
  },
  BANK_ACCOUNT: {
    id: 'BANK_ACCOUNT',
    defaultMessage: 'Bank Account',
  },
  MERCK_BANK_NAME_CHINA: {
    id: 'MERCK_BANK_NAME_CHINA',
    defaultMessage: '招商银行股份有限公司上海长阳支行',
  },
  MERCK_CHEMICALS_BANK_NAME_CHINA: {
    id: 'MERCK_CHEMICALS_BANK_NAME_CHINA',
    defaultMessage: '德意志银行（中国）有限公司上海分行',
  },
  MERCK_BANK_ACCOUNT_CHINA: {
    id: 'MERCK_BANK_ACCOUNT_CHINA',
    defaultMessage: '214081791310001',
  },
  MERCK_CHEMICALS_BANK_ACCOUNT_CHINA: {
    id: 'MERCK_CHEMICALS_BANK_ACCOUNT_CHINA',
    defaultMessage: '3516887015',
  },
  FEE_DISCOUNTS: {
    id: 'FEE_DISCOUNTS',
    defaultMessage: 'Fees and Discounts',
  },
  ESTIMATED_TOTAL: {
    id: 'ESTIMATED_TOTAL',
    defaultMessage: 'Estimated Total',
  },
  APPLIED_CHECKOUT: {
    id: 'APPLIED_CHECKOUT',
    defaultMessage: 'Applied at Checkout',
  },
  EACH: {
    id: 'EACH',
    defaultMessage: 'Each',
  },
  PACK_SIZE: { id: 'PACK_SIZE', defaultMessage: 'Pack Size' },
  THIS_KIT_CONTAINS: {
    id: 'THIS_KIT_CONTAINS',
    defaultMessage: 'This Bundle Includes:',
  },
  AX: { id: 'AX', defaultMessage: 'American Express' },
  VI: { id: 'VI', defaultMessage: 'Visa' },
  MC: { id: 'MC', defaultMessage: 'Mastercard' },
})

export default messages
