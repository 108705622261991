import {
  CartFragment,
  CartItemFragment,
} from '@src/queries/CartQuery.generated'
import { SavedCartItemFragment } from '@src/queries/SavedCartQuery.generated'
import { CartRequestAction, CartItemType } from '@src/types/graphql-types'
import {
  cartRoute,
  CartType,
  CartView,
  CartFddLevel,
  EndUserCartType,
} from '@src/routes'
import { MultipleMinQtyAlertErrorsType } from '@src/routes/Cart/ShoppingCart/ShoppingCart'
import messages from './messages'
import { UserUnion } from './useCurrentUser'
import {
  CreditCardExpireDateData,
  MonthsData,
  YearsData,
} from './analytics/types'

// TODO: Is there a more complete list of these? Hardcoding them feels gross
export const PROMO_CODE_ERROR_MESSAGES = {
  '570': messages.PROMO_CODE_INVALID,
  '571': messages.PROMO_CODE_EXPIRED,
  '572': messages.PROMO_CODE_NOT_YET_VALID,
  '573': messages.PROMO_CODE_NOT_APPLIED,
}

export const PROMO_CODE_ERROR_KEYS = Object.keys(PROMO_CODE_ERROR_MESSAGES)

export const hasCartItemPromoCodeError = (
  cartItem: CartItemFragment
): boolean => {
  return PROMO_CODE_ERROR_KEYS.includes(
    (!!cartItem?.errors && cartItem?.errors[0]?.errorNumber) || ''
  )
}

export const hasSavedCartItemPromoCodeError = (
  cartItem: SavedCartItemFragment
): boolean =>
  PROMO_CODE_ERROR_KEYS.includes(cartItem.itemSAPError?.[0]?.errorNumber || '')

export const hasCartItemMinimumOrderError = (
  cartItem: CartItemFragment
): boolean => {
  const min = cartItem.minimumOrderQuantity || 0
  const quantity = cartItem.quantity
  return quantity < min
}

export const hasMultipleMiniMumOrderQuantityError = (
  quantity,
  minOrderQuantity,
  isIndonesiaUser
): boolean => {
  const min = minOrderQuantity
  if (!min || !quantity) {
    return false
  }
  return isIndonesiaUser ? quantity < min : quantity % min !== 0
}

export const hasCartItemMultipleMiniMumOrderError = (
  cartItem: MultipleMinQtyAlertErrorsType,
  isIndonesiaUser?: boolean
): boolean => {
  const min = cartItem.minimumOrderQuantity
  const quantity = cartItem.quantity
  if (!min || !quantity) {
    return false
  }
  return isIndonesiaUser ? quantity < min : quantity % min !== 0
}

export const checkMultipleMinimumQuantityError = (
  cartItems: MultipleMinQtyAlertErrorsType[],
  setShowAlert?: Function,
  isIndonesiaUser?: boolean
): boolean => {
  const minQtyErrorsArray = cartItems.map((item) => ({
    minimumOrderQuantity: item.minimumOrderQuantity,
    materialNumber: item.materialNumber,
    quantity: item.quantity,
    error: hasCartItemMultipleMiniMumOrderError(item, isIndonesiaUser),
    id: item.id,
  }))
  setShowAlert && setShowAlert(minQtyErrorsArray)
  return minQtyErrorsArray.some((item) => item.error)
}

export const materialIdIsPromotionalBundle = (materialId: string): boolean =>
  String(materialId).startsWith('BNDL')

export const getCartMiniAction = (
  cartType: CartType = CartType.Active
): CartRequestAction => {
  switch (cartType) {
    case CartType.SavedCart:
      return CartRequestAction.Savedcartmini
    case CartType.BlueSavedCart:
      return CartRequestAction.Bluesavedcart
    case CartType.BuyNowCart:
      return CartRequestAction.Buyitnowcart
    case CartType.MarketplaceBuyNowCart:
      return CartRequestAction.Marketplacebuyitnowcart
    case CartType.ContractCart:
      return CartRequestAction.Contractcart
    case CartType.DealerCart:
      return CartRequestAction.Dealercartmini
    case CartType.MarketplaceCart:
      return CartRequestAction.Marketplace
    case CartType.BlueCart:
    case CartType.Emerald:
    case CartType.Iscala:
    case CartType.Phoenix:
    case CartType.Orc:
      return CartRequestAction.Bluecartmini
    case CartType.Quotefullyconvertcart:
      return CartRequestAction.Quotefullyconvertcartmini
    default:
      return CartRequestAction.Mini
  }
}

export const getMonths = (placeHolder: string): MonthsData[] => [
  { value: '01', label: '01' },
  { value: '02', label: '02' },
  { value: '03', label: '03' },
  { value: '04', label: '04' },
  { value: '05', label: '05' },
  { value: '06', label: '06' },
  { value: '07', label: '07' },
  { value: '08', label: '08' },
  { value: '09', label: '09' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '', label: placeHolder },
]

export const getYears = (
  start,
  stop,
  step,
  placeHolder: string
): YearsData[] => {
  const years = Array.from(
    { length: (stop - start) / step + 1 },
    (_, i) => start + i * step
  )
  const yearsOptions = years.map((year) => ({ value: year, label: year }))

  return [...yearsOptions, { value: '', label: placeHolder }]
}

export const getCreditCardType = (cardNumber: string): string | null => {
  if (cardNumber && /^3[0-9]{14}$/.test(cardNumber)) {
    return 'AX'
  } else if (cardNumber && /^4[0-9]{15}$/.test(cardNumber)) {
    return 'VI'
  } else if (cardNumber && /^5[0-9]{15}$/.test(cardNumber)) {
    return 'MC'
  }
  return null
}

export const getCCExpireDate = (
  expiryDate?: string | undefined | Maybe<string>
): CreditCardExpireDateData => {
  const splitDate = (expiryDate ?? '/')?.split('/')

  return { month: splitDate?.[0], year: splitDate?.[1] }
}
// get cart type Param for fetchPartners api
export const getCartTypeParam = (
  isBlueErpIntegrationEnabled: boolean,
  cartType: CartType = CartType.Active
): CartRequestAction | null => {
  if (isBlueErpIntegrationEnabled) {
    switch (cartType) {
      case CartType.BlueCart:
      case CartType.BlueSavedCart:
        return CartRequestAction.Bluecart
      default:
        return null
    }
  }
  return null
}

export const getRedirctCartRoute = (
  cartType: string | null | undefined
): string => {
  let redirctCartType: CartType
  if (cartType === 'marketplace') {
    redirctCartType = CartType.MarketplaceCart
  } else if (isBlueCartBE(cartType)) {
    redirctCartType = CartType.BlueCart
  } else {
    redirctCartType = CartType.Active
  }
  return cartRoute[CartView.Cart](redirctCartType)
}

export const isRedCartFE = (cartType: CartType | undefined): boolean => {
  return cartType === CartType.Active || cartType === CartType.SavedCart
}

export const displayPromoCode = (
  cartType: CartType | undefined,
  cart: CartFragment | undefined
): boolean => {
  const isBlueCartEnabled = isBlueCartFE(cartType) && !!cart?.displayPromoCode
  return isRedCartFE(cartType) || isBlueCartEnabled
}

//check cart type based on BE cart response
//order type is the same as cart type, so we can use it to check if it's blue order too.
export function isBlueCartBE(
  cartType: CartType | null | undefined | string
): boolean {
  return (
    cartType === CartType.Emerald ||
    cartType === CartType.Iscala ||
    cartType === CartType.Phoenix ||
    cartType === CartType.Orc
  )
}
//check cart type based on type of FE URL
export function isBlueCartFE(
  cartType: CartType | null | undefined | string | string[]
): boolean {
  return cartType === CartType.BlueCart || cartType === CartType.BlueSavedCart
}

export const isRedOrBlueFTBCart = (
  cartType: CartType | null | undefined | string | string[]
): boolean => {
  return cartType === CartType.FTBCart || cartType === CartType.BlueFTBCart
}

export const isStandingOrdersDisabled = (item: CartItemFragment): boolean =>
  !!item.promoCode || !!item.minimumOrderQuantity || item.quantity === 1

export const isFddFieldEnabled = (
  cartType: string,
  currentUser: UserUnion | null
): boolean =>
  currentUser?.__typename === 'LoggedInUser' &&
  (currentUser?.metadata?.isFddEnabled || currentUser?.isDarmstadtUser) &&
  cartType !== 'ftbcart' &&
  cartType !== EndUserCartType.DealerEnduserCart &&
  cartType !== EndUserCartType.DealerFtbCart

export const getShouldRenderSelectShippingDate = (
  item: CartItemFragment,
  cartType = '',
  currentUser: UserUnion | null,
  fromQuote: boolean
): boolean => {
  return (
    currentUser?.__typename === 'LoggedInUser' &&
    ((currentUser?.metadata?.isFddEnabled &&
      currentUser?.metadata?.fddLevel === CartFddLevel.Item) ||
      // support DarmstadtUser
      currentUser?.isDarmstadtUser) &&
    !fromQuote &&
    item.type === CartItemType.Prepack &&
    cartType !== 'ftbcart' &&
    cartType !== EndUserCartType.DealerEnduserCart &&
    cartType !== EndUserCartType.DealerFtbCart &&
    cartType !== CartType.MarketplaceCart &&
    cartType !== CartType.MarketplaceBuyNowCart &&
    cartType !== CartType.Quotefullyconvertcart
  )
}

export const getCheckoutItemElementId = (item: CartItemFragment): string => {
  return `checkout-item-${item.id}`
}
