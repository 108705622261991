import { useContext, useEffect, useRef, useState } from 'react'
import { FormattedMessage, FormattedMessageProps } from 'react-intl'
import { Analytics } from '@sial/common-utils'
import { MaterialAvailability } from '@src/types/graphql-types'
import { TrackedAvailabilityMessageContext } from './TrackedAvailabilityMessageContext'
import dayjs from 'dayjs'
import { pickBy } from 'lodash'
import { useLatest } from 'react-use'

const getDayDiff = (utcTimestamp: number): number => {
  const dFinal = new Date(utcTimestamp).toISOString().split('T')[0] // e.g. '2024-02-29'
  return dayjs(dFinal).diff(dayjs().startOf('d'), 'd')
}

type Availability = Pick<
  MaterialAvailability,
  | 'plantLoc'
  | 'date'
  | 'quantity'
  | 'availabilityOverwriteMessage'
  | 'supplementaryMessage'
>

type TrackedAvailabilityMessageProps = FormattedMessageProps & {
  availability?: Availability
}

export const useAvailabilityMessageTracking = (
  messageId: FormattedMessageProps['id'],
  availability?: Availability
): void => {
  const ctx = useContext(TrackedAvailabilityMessageContext)
  const { brand, item, source, replacementProducts, pricing } = ctx || {}
  const { plantLoc, date, quantity } = availability || {}
  const availabilityOverwriteMessage =
    availability?.availabilityOverwriteMessage?.messageKey
  const supplementaryMessage = availability?.supplementaryMessage?.messageKey
  const latestPricingRef = useLatest(pricing)

  useEffect(() => {
    if (!messageId || !item || !source) return
    const latestPricing = latestPricingRef.current
    Analytics.sendGTMEvent({
      eventType: 'fs_availability_viewed',
      payload: pickBy(
        {
          fs_item: `${brand}|${item}`,
          fs_message: messageId,
          fs_lead_time_in_days: date ? getDayDiff(date) : null,
          fs_warehouse: plantLoc,
          fs_quantity_checked: quantity,
          fs_source: source,
          fs_replacement_product_shown: replacementProducts
            ?.map((p) => `${p.brand.key}|${p.productNumber}`)
            .join(', '),
          fs_currency: latestPricing?.currency,
          fs_listPriceCurrency: latestPricing?.listPriceCurrency,
          fs_listPrice: latestPricing?.listPrice || null,
          fs_netPrice: latestPricing?.netPrice || null,
          fs_price: latestPricing?.price || null,
          fs_productGroupSBU: latestPricing?.productGroupSBU,
          fs_productHierarchy: latestPricing?.productHierarchy,
          fs_promotionalMessage: latestPricing?.promotionalMessage?.messageKey,

          // Some responses contain these properties in the availability object (P&A), some at the item level (Cart)
          fs_supplementaryMessage:
            supplementaryMessage ||
            latestPricing?.availabilities?.[0]?.supplementaryMessage
              ?.messageKey ||
            latestPricing?.supplementaryMessage?.messageKey,
          fs_availabilityOverwriteMessage:
            availabilityOverwriteMessage ||
            latestPricing?.availabilities?.[0]?.availabilityOverwriteMessage
              ?.messageKey ||
            latestPricing?.availabilityOverwriteMessage?.messageKey,
        },
        (v) => v != null
      ),
    })
  }, [
    // Unless there's a good reason not to, only primitives (or refs) should be used in this
    // useEffect's deps to prevent the event from firing more than it needs to.
    // E.g. refreshing a List's items would yield new objects but potentially the same exact data.
    brand,
    item,
    source,
    date,
    messageId,
    plantLoc,
    quantity,
    replacementProducts,
    latestPricingRef,
    supplementaryMessage,
    availabilityOverwriteMessage,
  ])
}

export const TrackedAvailabilityMessage = ({
  id,
  defaultMessage,
  values,
  availability,
  children,
}: TrackedAvailabilityMessageProps) => {
  const ref = useRef(null)
  const { waitUntilVisible } =
    useContext(TrackedAvailabilityMessageContext) || {}
  const [wasVisible, setWasVisible] = useState(!waitUntilVisible)
  useAvailabilityMessageTracking(wasVisible ? id : '', availability)

  useEffect(() => {
    if (!ref.current) return
    const observer = new IntersectionObserver((entries) => {
      const isIntersecting = entries.some((e) => e.isIntersecting)
      if (isIntersecting) {
        setWasVisible(true)
        observer.disconnect()
      }
    })
    observer.observe(ref.current)
    return () => observer.disconnect()
  }, [])

  return (
    <>
      {waitUntilVisible && <span ref={ref} />}
      <FormattedMessage id={id} defaultMessage={defaultMessage} values={values}>
        {children}
      </FormattedMessage>
    </>
  )
}
