import {
  DiscontinuedMaterialPricingDetailFragment,
  ValidMaterialPricingDetailFragment,
} from '@src/fragments/ProductPricing.generated'
import { createContext, PropsWithChildren } from 'react'

export type Pricing = {
  [K in
    | 'currency'
    | 'listPriceCurrency'
    | 'listPrice'
    | 'netPrice'
    | 'price'
    | 'productGroupSBU'
    | 'availabilities'
    | 'productHierarchy']?: ValidMaterialPricingDetailFragment[K] | null
} & {
  promotionalMessage?: { messageKey?: string | null } | null
  availabilityOverwriteMessage?: { messageKey?: string | null } | null
  supplementaryMessage?: { messageKey?: string | null } | null
}

interface TrackedAvailabilityMessageContextValue {
  source?: string
  brand?: string
  item?: string
  replacementProducts?: DiscontinuedMaterialPricingDetailFragment['replacementProducts']
  pricing?: Pricing | null
  waitUntilVisible?: boolean
}

export const TrackedAvailabilityMessageContext =
  createContext<TrackedAvailabilityMessageContextValue | null>(null)

export const TrackedAvailabilityMessageProvider = ({
  children,
  ...value
}: PropsWithChildren<TrackedAvailabilityMessageContextValue>) => (
  <TrackedAvailabilityMessageContext.Provider value={value}>
    {children}
  </TrackedAvailabilityMessageContext.Provider>
)
