import Icon, { BaseIconProps } from './Icon'
function CheckMarkCircleIcon({ ...props }: BaseIconProps) {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 20 20"
        {...props}
      >
        <path d="M10 20C4.48 20 0 15.52 0 10S4.48 0 10 0s10 4.48 10 10-4.48 10-10 10m0-18.13C5.51 1.86 1.87 5.5 1.86 9.99s3.63 8.13 8.12 8.14 8.13-3.63 8.14-8.12V10c-.01-4.48-3.64-8.12-8.12-8.13" />
        <path d="M8.88 13.67c-.25 0-.5-.1-.67-.28l-2.83-2.91c-.33-.4-.28-.99.12-1.32.35-.29.87-.29 1.22.01l2.19 2.25 4.8-4.56c.37-.34.94-.32 1.29.04.37.37.37.96 0 1.33l-5.48 5.19c-.17.16-.4.25-.64.25" />
      </svg>
    </Icon>
  )
}
export default CheckMarkCircleIcon
